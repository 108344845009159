import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as InstagramLogo } from '../../../svg/instagram.svg';
import { ReactComponent as FacebookLogo } from '../../../svg/facebook.svg';

const Wrapper = styled('section')`
  margin-right: 0;

  ul {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    ${({ theme }) => theme.above.md} {
      justify-content: flex-end;
    }
  }

  li {
    margin: 5px;

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  a {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  color: ${({ theme }) => theme.colors.black};
`;

const LogoWrapper = styled('span')`
  width: 24px;
  height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 24px;
  }
`;

const SocialLinks = ({ facebook, instagram }) => {
  return (
    <Wrapper>
      <ul>
        {facebook ? (
          <li>
            <a href={facebook} target="_blank" rel="noopener noreferrer">
              <LogoWrapper>
                <FacebookLogo />
              </LogoWrapper>
            </a>
          </li>
        ) : null}

        {instagram ? (
          <li>
            <a href={instagram} target="_blank" rel="noopener noreferrer">
              <LogoWrapper>
                <InstagramLogo />
              </LogoWrapper>
            </a>
          </li>
        ) : null}
      </ul>
    </Wrapper>
  );
};

export default SocialLinks;
