import React, { Component } from 'react';
import styled from 'react-emotion';

const UIProductGrid = styled('div')`
  display: flex;
  flex-wrap: wrap;

  & > div {
    width: 50%;

    @media (min-width: 900px) {
      width: 33.33%;
    }
  }
`;

class ProductGrid extends Component {
  componentDidMount() {
    let { layout } = this.props;

    if (layout !== this.state.layout) {
      this.setState({
        layout: layout ? layout : 'grid'
      });
    }
  }

  state = {
    layout: 'grid'
  };

  render() {
    let { className } = this.props;

    return (
      <UIProductGrid className={className}>{this.props.children}</UIProductGrid>
    );
  }
}

export default ProductGrid;
