export const featuredCategoryIds = [
  // Musikinstrument
  1052,
  468,
  467,
  867,
  913,
  // Bocker and Biblar
  1002,
  163,
  166,
  1116,
  868,
  873,
  897,
  973,
  1184,
  // Musik, Film and Noter
  272,
  1100,
  265,
  910,
  869,
  // Forsamlingsmaterial
  1064,
  // Persenter and Prylar
  470,
  1006
];
