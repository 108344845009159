export const BANNER_PRODUCT_IDS = [
  '410927',
  '410928',
  '410929',
  '410930',
  '410931',
  '410932',
  '410933',
  '410934',
  '410935',
  '410936',
  '410937',
  '410938'
];
