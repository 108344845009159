import React, { Component } from 'react';
import styled from 'react-emotion';
import ReactModal from 'react-modal';
import OptionSwitch from '@jetshop/core/components/OptionSwitch';
import ProductLink from '@jetshop/ui/ProductLink';
import CloseBtn from '../CloseBtn';
import { DefaultPicker } from '../../ProductPage/ProductPickers';
import SubNameLink from '../../ProductPage/SubNameLink';
import ImageContainer from '../../ProductPage/Images/ImageContainer';
import AddToCartWrapper from '../../ProductPage/AddToCart/AddToCartWrapper';
import CategoryLinks from '../ProductItems/CategoryLinks';

const menuAniTime = 250;

const Modal = styled(ReactModal)`
  position: relative;
  width: calc(100% + 20px);
  max-width: 500px;
  margin: 60px auto;
  padding: 60px 40px;

  opacity: 0;
  box-shadow: ${({ theme }) => theme.boxshadow};
  background-color: ${({ theme }) => theme.colors.white};
  outline: 0;
  transform: translateY(50px);
  transition: all ${menuAniTime}ms ease;

  &.ReactModal__Content--after-open {
    opacity: 1;
    transform: translateY(0px);
  }

  &.ReactModal__Content--before-close {
    opacity: 0;
    transform: translateY(-50px);
  }
`;

const CloseBtnContainer = styled('div')`
  position: absolute;
  top: 30px;
  right: 40px;
`;

const Title = styled('h3')`
  text-align: center;
  font-weight: 700;
`;

const TextCenter = styled('div')`
  text-align: center;

  span {
    margin-top: 5px;
  }
`;

const StyledImageContainer = styled(ImageContainer)`
  max-width: 300px;
  margin: 30px auto;
`;

const OptionsWrapper = styled('div')`
  margin-bottom: 10px;
`;

const GoToProduct = styled(ProductLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  margin-top: 30px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gradientBlack1};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.gradientBlack1},
    ${({ theme }) => theme.colors.gradientBlack2}
  );
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  cursor: pointer;
`;

class QuickLookModal extends Component {
  render() {
    const { product, stockStatus, isOpen, categoryPath } = this.props;
    const { canonicalCategory } = product;

    return (
      <Modal
        isOpen={isOpen}
        onRequestClose={this.props.onCloseMenu}
        closeTimeoutMS={menuAniTime}
        openTimeoutMS={menuAniTime}
        style={{
          overlay: {
            zIndex: 100,
            overflow: 'auto'
          }
        }}
      >
        <CloseBtnContainer>
          <CloseBtn onClick={() => this.props.onCloseMenu()} />
        </CloseBtnContainer>

        <TextCenter>
          <Title className="hero-size">{product.name}</Title>
          <CategoryLinks product={product} />
        </TextCenter>

        {product.images.length > 0 && (
          <StyledImageContainer
            images={product.images}
            badges={product.badges}
          />
        )}

        {product.variants && product.variants.options ? (
          <OptionsWrapper>
            <OptionSwitch
              options={product.variants.options}
              components={{
                default: DefaultPicker
              }}
            />
          </OptionsWrapper>
        ) : null}

        <AddToCartWrapper product={product} stockStatus={stockStatus} />

        <GoToProduct product={product} categoryPath={categoryPath}>
          Till produktsida
        </GoToProduct>
      </Modal>
    );
  }
}

export default QuickLookModal;
