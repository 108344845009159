import React from 'react';
import styled from 'react-emotion';

const StyledBadgeItemWrapper = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${props => props.dynamicColor};
  border-radius: 2rem;
`;
const StyledBadgeItemText = styled('span')`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1rem;
  font-weight: 300;
  line-height: 1.25;
  letter-spacing: normal;
  padding: 2px 7px 3px 7px;
  line-height: 1.2;

  ${({ theme }) => theme.above.xxs} {
    padding: 5px 13px 6px 14px;
  }

  ${({ theme }) => theme.above.xs} {
    font-size: 1.2rem;
    padding: 5px 13px 6px 14px;
  }
`;

const BadgeItem = ({ badgeItem }) => {
  return (
    <StyledBadgeItemWrapper dynamicColor={badgeItem.color}>
      <StyledBadgeItemText>{badgeItem.title}</StyledBadgeItemText>
    </StyledBadgeItemWrapper>
  );
};

export default BadgeItem;
