import { default as React, Component } from 'react';
import styled from 'react-emotion';
import { Below, Above } from '@jetshop/ui/Breakpoints';
import Link from '../../utility/Link';

import CartButton from '../../Cart/CartButton';
import MenuBtn from './MobileNavigation/MenuBtn';
import SearchBar from './SearchBar';

import { ReactComponent as UserIcon } from '../../../svg/user-icon.svg';
import { ReactComponent as PrimaryLogo } from '../../../svg/nya-musik-logo.svg';

const Wrapper = styled('div')`
  display: flex;
  justify-content: flex-end;
  padding: 12.5px 0px;
  transition: all 250ms ease;

  ${({ theme }) => theme.above.md} {
    padding: 20px 0px;
  }
`;

const InnerWrap = styled('div')`
  position: relative;
  display: flex;
  align-items: center;
  transition: all 250ms ease;
  z-index: 1;

  ${({ theme }) => theme.below.md} {
    opacity: ${({ searchOpen }) => (searchOpen ? '0' : '1')};
    visibility: ${({ searchOpen }) => (searchOpen ? 'hidden' : 'visible')};

    &:not(:first-of-type) {
      padding-left: 40px;
    }
  }
`;

const LogoWrap = styled(InnerWrap)`
  height: 35px;
  margin-right: auto;

  ${({ theme }) => theme.below.md} {
    opacity: ${({ searchOpen, menuOpen }) =>
      searchOpen || menuOpen ? '0' : '1'} !important;
    visibility: ${({ searchOpen, menuOpen }) =>
      searchOpen || menuOpen ? 'hidden' : 'visible'} !important;
  }

  ${({ theme }) => theme.above.md} {
    width: 230px;
    height: 50px;
    justify-content: flex-start;
    margin-right: 0px;
    text-align: left;
  }
`;

const SearchWrap = styled(InnerWrap)`
  ${({ theme }) => theme.below.md} {
    width: calc(24px + 40px);
    z-index: 1;
  }

  ${({ theme }) => theme.above.md} {
    flex-grow: 1;
    justify-content: center;
    padding: 0px 35px;
    text-align: center;
  }
`;

const IconWrap = styled(InnerWrap)`
  ${({ theme }) => theme.above.md} {
    justify-content: flex-end;
    text-align: right;
  }

  ${({ theme }) => theme.above.md} {
    width: 230px;
  }
`;

const Logo = styled('img')`
  max-height: 35px;
  max-width: 100%;
  transition: all 250ms ease;

  ${({ theme }) => theme.above.md} {
    max-height: 50px;
  }
`;

const StyledLogo = styled(PrimaryLogo)`
  max-height: 35px;
  max-width: 100%;
  transition: all 250ms ease;
  ${({ theme }) => theme.above.md} {
    max-height: 50px;
  }

  g {
    fill: black;
  }
`;

const IconContainer = styled('span')`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => theme.above.md} {
    width: 35px;
    height: 35px;
  }

  &:not(:first-of-type) {
    margin-left: 30px;
  }

  svg {
    height: 24px;
    width: 24px;

    ${({ theme }) => theme.above.md} {
      width: 35px;
      height: 35px;
    }
  }
`;

class BrandNav extends Component {
  handleToggleMenu = menuOpen => {
    if (this.props.onToggleMenu) {
      this.props.onToggleMenu(menuOpen);
    }
  };

  handleToggleSearch = searchOpen => {
    if (this.props.onToggleSearch) {
      this.props.onToggleSearch(searchOpen);
    }
  };

  render() {
    let { logo, searchOpen, menuOpen, loginLink } = this.props;

    return (
      <Wrapper>
        <LogoWrap searchOpen={searchOpen} menuOpen={menuOpen}>
          {logo && (
            <Link to="/">
              <StyledLogo />
            </Link>
          )}
        </LogoWrap>

        <SearchWrap>
          <SearchBar
            onToggleSearch={searchOpen => this.handleToggleSearch(searchOpen)}
            open={searchOpen}
          />
        </SearchWrap>

        <IconWrap searchOpen={searchOpen}>
          <IconContainer>
            <CartButton />
          </IconContainer>

          {loginLink && (
            <Above breakpoint="md">
              {matches =>
                matches ? (
                  <IconContainer>
                    <Link to={loginLink}>
                      <UserIcon />
                    </Link>
                  </IconContainer>
                ) : null
              }
            </Above>
          )}
        </IconWrap>

        <Below breakpoint="md">
          {matches =>
            matches ? (
              <InnerWrap searchOpen={searchOpen}>
                <MenuBtn
                  onToggleMenu={menuOpen => this.handleToggleMenu(menuOpen)}
                  open={menuOpen}
                />
              </InnerWrap>
            ) : null
          }
        </Below>
      </Wrapper>
    );
  }
}

export default BrandNav;
