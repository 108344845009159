import React from 'react';
import get from 'lodash.get';
import styled from 'react-emotion';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Drawer, { DrawerTarget } from '@jetshop/ui/Modal/Drawer';
import DrawerHeader, {
  DrawerInner
} from '../CategoryPage/Filters/DrawerHeader';
import { Price } from '@jetshop/ui/Price';
import Button from '../ui/Button';
import CartItem from './CartItem';
import cartQuery from './CartQuery.gql';
//dont remove import, breaks bundleorder for some reason
import HelloRetailRecommendations from '../HelloRetail';

const CartItems = styled('section')`
  padding: 20px 10px;
  margin-bottom: 20px;
  border-top: 1px solid ${({ theme }) => theme.colors.lightGrey};
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
`;

const Summary = styled('section')`
  padding: 10px 10px 20px;
`;

const TotalSummary = styled('div')`
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  color: ${({ theme }) => theme.colors.black};
  font-size: ${({ theme }) => theme.fontSizes.regular};
  font-weight: 700;
`;

const Checkout = styled(Button)`
  max-width: 100% !important;
  border-radius: 40px !important;
  padding: 1.25rem 1rem !important;
  font-size: 16px !important;
  &:hover {
    scale: 1.02;
    opacity: 0.8;
    text-decoration: none;
    color: white;
  }
`;
const RecommendedWrapper = styled('div')``;

const CartFlyoutView = ({ cart, modal, ...rest }) => {
  const items = get(cart, 'data.cart.items', []);
  const itemCount = get(cart, 'data.cart.totalQuantity', 0);
  const checkoutUrl = get(cart, 'data.cart.externalCheckoutUrl');
  const track = useTracker();
  return (
    <DrawerInner {...rest}>
      <DrawerHeader
        title="Varukorg"
        itemCount={itemCount}
        onClose={() => modal.hideTarget()}
      />
      <Summary>
        <TotalSummary>
          <label>{t('Total')}</label>
          <Price price={cart.data.cart.productTotal} />
        </TotalSummary>

        {checkoutUrl && (
          <Checkout
            anchor={true}
            href={checkoutUrl}
            onClick={() => {
              track(trackCartCheckoutEvent({ cart: cart.data.cart }));
            }}
          >
            {t('Check out')}
          </Checkout>
        )}
      </Summary>
      <CartItems>
        {items.map(item => (
          <CartItem item={item} key={item.id} />
        ))}
      </CartItems>
      <RecommendedWrapper>
        <HelloRetailRecommendations
          cart={true}
          recommendationsList={[
            {
              title: 'Komplettera ditt köp med',
              recommendationId: 'k6203b41622cae73d2f62ef9e',
              cart: cart.items
            }
          ]}
        />
      </RecommendedWrapper>
    </DrawerInner>
  );
};

const CartFlyout = props => (
  // Wrap the flyout with the needed providers
  <CartProvider query={cartQuery}>
    {cart =>
      cart.data && cart.data.cart ? (
        <DrawerTarget id="cart-drawer">
          {drawer => (
            <Drawer isOpen={drawer.isOpen} right>
              <CartFlyoutView modal={drawer} cart={cart} {...props} />
            </Drawer>
          )}
        </DrawerTarget>
      ) : null
    }
  </CartProvider>
);

export default CartFlyout;
