import React from 'react';
import useDecrementQuantity from '@jetshop/core/components/Mutation/useDecrementQuantity';
import useIncrementQuantity from '@jetshop/core/components/Mutation/useIncrementQuantity';
import useRemoveFromCart from '@jetshop/core/components/Mutation/useRemoveFromCart';
import getCartItemVariant from '@jetshop/core/helpers/getCartItemVariant';
import Image from '@jetshop/ui/Image';
import CloseBtn from '../ui/CloseBtn';
import { Price } from '@jetshop/ui/Price';
import styled from 'react-emotion';
import removeFromCartMutation from './removeFromCart.gql';
import incrementQuantityMutation from './incrementQuantity.gql';
import decrementQuantityMutation from './decrementQuantity.gql';
import cartQuery from './CartQuery.gql';
import Link from '../utility/Link';

const Wrapper = styled('div')`
  display: flex;
  padding: 10px;
  margin-bottom: 5px;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxshadow};

  > :first-child {
    flex: 1 1 25%;
  }

  > :last-child {
    flex: 1 1 70%;
    padding: 5px;
  }
`;

const Title = styled('h3')`
  color: ${({ theme }) => theme.colors.black};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ProductDetail = styled('section')`
  display: flex;
  flex-wrap: wrap;
  max-width: calc(100% - 60px);
`;

const ItemDetails = styled('div')`
  position: relative;
  width: 100%;
  padding-right: 35px;
  padding-bottom: 10px;
`;

const ProductName = styled(Title)`
  ${({ theme }) => theme.fontSizes.regular}
`;

const VariationName = styled(Title)`
  margin-top: 5px;
  ${({ theme }) => theme.fontSizes.description}
`;

const Button = styled(CloseBtn)`
  width: 20px;
  height: 20px;
  box-shadow: none;
  background-color: ${({ theme }) => theme.colors.lightGrey};
`;

const RemoveItem = styled(Button)`
  position: absolute;
  top: 2px;
  right: 0px;
`;

const PriceWrapper = styled('div')`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const AdjustQty = styled('div')`
  display: flex;

  span {
    margin: 0 8px;
    line-height: 30px;
    display: inline-block;
  }
`;

const DecrementBtn = styled(Button)`
  transform: rotate(45deg);

  &::before {
    width: 35%;
  }

  &::after {
    display: none;
  }
`;

const IncrementBtn = styled(Button)`
  transform: rotate(45deg);
`;

const ProductLink = styled(Link)`
  text-decoration: none;
`;

const CartItem = ({ item, className = '' }) => {
  const {
    isVariant,
    variantImage,
    variantValues,
    hasVariantImage
  } = getCartItemVariant(item);

  const { incrementQuantity } = useIncrementQuantity({
    incrementQuantityMutation,
    cartQuery
  });

  const { decrementQuantity } = useDecrementQuantity({
    decrementQuantityMutation,
    cartQuery
  });

  const { removeFromCart } = useRemoveFromCart({
    removeFromCartMutation,
    cartQuery
  });

  return (
    <Wrapper className={className}>
      <div>
        {item.product.images.length > 0 && (
          <Image
            aspect="1:1"
            sizes={60}
            src={
              hasVariantImage ? variantImage.url : item.product.images[0].url
            }
            alt={
              hasVariantImage ? variantImage.alt : item.product.images[0].alt
            }
          />
        )}
      </div>
      <ProductDetail>
        <ItemDetails>
          <ProductLink to={item?.product?.primaryRoute?.path}>
            <ProductName title={item.product.name}>
              {item.product.name}
            </ProductName>
          </ProductLink>

          {isVariant && (
            <VariationName title={variantValues.join(', ')}>
              {variantValues.join(', ')}
            </VariationName>
          )}
          <RemoveItem
            onClick={() =>
              removeFromCart({ itemId: item.id, product: item.product })
            }
            data-testid="remove-from-cart"
          />
        </ItemDetails>

        <PriceWrapper>
          <AdjustQty>
            <DecrementBtn
              data-testid="decrement-quantity"
              disabled={item.quantity === 1}
              onClick={() =>
                item.quantity !== 1 && decrementQuantity({ itemId: item.id })
              }
            />
            <span>{item.quantity}</span>
            <IncrementBtn
              data-testid="increment-quantity"
              onClick={() => incrementQuantity({ itemId: item.id })}
            />
          </AdjustQty>

          <Price price={item.total} />
        </PriceWrapper>
      </ProductDetail>
    </Wrapper>
  );
};

export default CartItem;
