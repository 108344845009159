import React from 'react';
import styled from 'react-emotion';

const Wrapper = styled('div')`
  margin: 2rem 0;
  width: 100%;

  .field-container {
    width: 100%;
    display: flex;
    gap: 1rem;
    justify-content: start;
    align-items: center;
  }
`;

const Label = styled('label')`
  margin-bottom: 0.5rem;
  color: #1d1d1d;
  font-size: 1.6rem;
  font-weight: 500;
`;

const InputField = styled('input')`
  width: 100%;
  font-size: 1.4rem;
  line-height: 1.25rem;
  padding: 0.8rem;
  border-width: 1px;
  border-radius: 0.5rem;
  background-color: rgb(249 250 251 / 1);
`;

const AddToCartComments = ({ comments, setComments }) => {
  const onCommentChange = e => {
    const value = e.target.value;
    if (value.length > 200) {
      return;
    }
    if (value == '' && comments.length > 0) {
      setComments([]);
      return;
    }
    setComments([{ name: 'Budskap', value: value }]);
  };

  return (
    <Wrapper>
      <div className="field-container">
        <Label>Budskap:</Label>
        <InputField onChange={onCommentChange} />
      </div>
    </Wrapper>
  );
};

export default AddToCartComments;
