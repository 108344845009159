import React, { useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import helloRetailQuery from './HelloRetailQuery.gql';
import { useAddwish } from '@jetshop/flight-addwish';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import styled, { cx } from 'react-emotion';
import { useLocation } from 'react-router-dom';
import ProductCarousel from './Content/ProductCarousel';
import CartProductCarousel from './Content/CartProductCarousel';
import { Above } from '@jetshop/ui/Breakpoints';
import { fetchHelloRetailRecoms } from './HelloRetail/Api';

const RecommendationRowWrapper = styled('div')`
  height: fit-content;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 0rem;
  ${({ theme }) => theme.below.md} {
    margin-top: 0rem;
  }

  &:empty {
    margin-top: 0rem;
  }
  > div &:empty {
    margin-top: 0rem;
  }
`;

const RecommendationWrapper = styled('div')`
  height: fit-content;
  width: 100%;
  margin-top: 70px;
  margin-bottom: 1rem;
  padding: 1rem 2rem;
  .hero-size {
    display: none;
  }
  > div {
    margin-top: 0;
  }

  ${({ theme }) => theme.below.md} {
    padding-top: 1rem;
    padding-bottom: 1rem;

    > div h2 {
      margin-top: 1rem;
    }
  }

  .product-grid {
    margin: 0;
  }

  &.cart {
    > div {
      padding: 0;
    }
  }
`;

const HelloRetailRecommendationRow = ({
  closeToast,
  title,
  products,
  cart,
  slider = false
}) => {
  return (
    <RecommendationWrapper
      className={cx('hello-retail-recommendations', cart && 'cart')}
    >
      {cart ? (
        <CartProductCarousel
          closeToast={closeToast}
          marginTop={'md'}
          title={title}
          products={products}
        />
      ) : (
        <Above breakpoint="md">
          {matches =>
            matches ? (
              <ProductCarousel
                marginTop={'md'}
                title={title}
                products={products}
              />
            ) : (
              <CartProductCarousel
                marginTop={'md'}
                title={title}
                products={products}
              />
            )
          }
        </Above>
      )}
    </RecommendationWrapper>
  );
};

/* Example of recommendationList: 
- list of objects containing a dictionary, 
  also possible to add "brand" to dictionary)
  slider (bool) set true if you always wants a slider instead of list
  cart will be passed as parameter in cartflyout
  parents will be passed as paramerter for categories
recommendationsList={[
  {
    title: 'Liknande produkter',
    recommendationId: 'k5f6c8fcb0001aa7397c150e8'
    slider: false,
  },
  {
    title: 'Andra köpte även',
    recommendationId: 'k5f6c8fcb0001aa7397c150a6'
    slider: false,
  }
]} */

export const HelloRetailRecommendations = ({
  closeToast,
  recommendationsList = [],
  cart
}) => {
  const { selectedChannel } = useContext(ChannelContext);
  let location = useLocation();
  let path = location.pathname;
  let urlPath = selectedChannel.url ? selectedChannel.url + path : null;
  let boxes = {};

  const [helloRetailRecoms, setHelloRetailRecoms] = useState(null);

  const fetchRecoms = async () => {
    const res = await fetchHelloRetailRecoms(
      '03aab3e5-1895-46de-8351-860f656c02fa',
      recommendationsList
    );
    setHelloRetailRecoms(res?.responses);
  };

  useEffect(() => {
    if (!helloRetailRecoms) {
      fetchRecoms();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [helloRetailRecoms]);

  recommendationsList.forEach(recommendation => {
    let box = {};
    if (urlPath) {
      box.url = urlPath;
    }
    if (recommendation?.cart?.items) {
      box.urls = recommendation?.cart?.items?.map(
        item => selectedChannel.url + item.product.primaryRoute.path
      );
    }
    if (recommendation?.cart?.productTotal?.incVat) {
      box.price = recommendation?.cart?.productTotal?.incVat;
    }
    if (recommendation?.cart?.items) {
      let cartItemsHirachies = recommendation?.cart?.items.map(item => {
        let array = [];
        for (let i = 0; i < item.product.primaryRoute.parents.length; i++) {
          const child = item.product.primaryRoute.parents[i];
          array.push(child.object.breadcrumbText);
        }
        return array.reverse();
      });
      box.hierarchies = cartItemsHirachies;
    } else if (recommendation.parents) {
      let parentHirachies = recommendation.parents.map(parent => {
        return parent.object?.breadcrumbText;
      });
      box.hierarchies = [parentHirachies.reverse()];
    }

    if (recommendation.brand) {
      box.brand = recommendation.brand;
    }
    let recId = [recommendation.recommendationId];
    boxes[recId] = box;
  });

  const { addWishResponseBoxItems } = useAddwish({
    boxes: boxes,
    pageLoaded: !loading
  });

  let totalProductNumbers = [];
  let recommendationRows = [];
  let items = [];
  recommendationsList.forEach(recommendation => {
    let recommendationRow = {
      title: recommendation.title ?? '',
      productNumbers: [],
      slider: recommendation.slider ?? false
    };

    if (!helloRetailRecoms) {
      return;
    }
    const numbers = helloRetailRecoms
      .find(rec => rec.key == recommendation?.recommendationId)
      ?.products.map(p => {
        return p?.productNumber;
      });

    recommendationRow.productNumbers = numbers;
    totalProductNumbers = totalProductNumbers.concat(numbers);
    items = helloRetailRecoms.find(
      rec => rec.key == recommendation?.recommendationId
    )?.products;
    recommendationRows.push(recommendationRow);
    // items = recommendationsList.flatMap(list =>
    //   addWishResponseBoxItems[list.recommendationId]?.items.map(item => item)
    // );

    // recommendationRow['productNumbers'] = addWishResponseBoxItems[
    //   recommendation.recommendationId
    // ]?.items.map(item => item.productNumber);
    // recommendationRows.push(recommendationRow);
    // totalProductNumbers = totalProductNumbers.concat(
    //   recommendationRow['productNumbers']
    // );
  });

  const helloRetailProductsQueryResult = useQuery(helloRetailQuery, {
    variables: {
      articleNumbers: totalProductNumbers
    },
    skip: totalProductNumbers.length === 0,
    errorPolicy: 'ignore'
  });

  const allProducts =
    helloRetailProductsQueryResult?.data?.products.map(product => ({
      ...product,
      addwishData: items.find(item => {
        // console.log('item?.productNumber', item?.productNumber);
        // console.log('product?.articleNumber', product?.articleNumber);
        // console.log('item', item);
        // console.log('product', product);
        return item?.productNumber === product?.articleNumber;
      })
    })) || [];

  const loading = () => {
    recommendationsList.forEach(recommendation => {
      if (
        addWishResponseBoxItems[recommendation.recommendationId].loading ===
        'LOADING'
      ) {
        return true;
      }
    });

    if (helloRetailProductsQueryResult.loading) {
      return true;
    }

    return false;
  };

  const rowData = recommendationRows.map(row => {
    return {
      title: row.title,
      slider: row.slider ?? false,
      products: allProducts.filter(product => {
        if (row.productNumbers.includes(product.articleNumber)) {
          return product;
        }
      })
    };
  });

  return (
    <RecommendationRowWrapper>
      {rowData.map(row =>
        row.products.length > 0 ? (
          <HelloRetailRecommendationRow
            closeToast={closeToast}
            cart={cart}
            {...row}
            key={row.title + row.products[0].articleNumber}
          />
        ) : null
      )}
    </RecommendationRowWrapper>
  );
};

export default HelloRetailRecommendations;
