import React, { Component } from 'react';
import styled, { keyframes } from 'react-emotion';

import { ReactComponent as Cart } from '../../svg/shopping-cart-v2.svg';

const IconWrapper = styled('div')`
  position: relative;
  width: 22px;
  height: 22px;

  ${({ theme }) => theme.above.md} {
    width: 30px;
    height: 30px;
  }

  svg {
    height: 22px;
    width: 22px;
    path {
      stroke: ${({ theme }) => theme.colors.steelGray};
    }

    ${({ theme }) => theme.above.md} {
      width: 30px;
      height: 30px;
    }
  }
`;

const pop = keyframes`
  0%, 100% {
  	transform: translate(40%, -40%) scale(1);
  }
  70% {
  	transform: translate(40%, -40%) scale(1.15);
  }
`;

const Qty = styled('span')`
  position: absolute;
  top: 0px;
  right: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  height: 18px;
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gradientPeach};
  font-size: ${props => (parseInt(props.qty) > 99 ? '8px' : '12px')};
  font-weight: 700;
  visibility: hidden;
  transform: translate(40%, -40%) scale(0);
  transition: font-size 250ms ease,
    transform 250ms cubic-bezier(0.68, -0.55, 0.265, 1.55);

  &.show {
    visibility: visible;
    transform: translate(40%, -40%) scale(1);

    &.pop {
      animation: ${pop} 100ms ease;
    }
  }
`;

class CartIcon extends Component {
  componentDidMount() {
    let { itemsInCart } = this.props;
    let qty = parseInt(itemsInCart);

    let displayQty = qty ? (qty > 99 ? '99+' : qty) : null;

    this.setState({
      qty: qty,
      displayQty: displayQty
    });
  }

  componentWillReceiveProps(nextProps) {
    let litemsInCart = parseInt(nextProps.itemsInCart);

    if (litemsInCart !== this.state.qty) {
      let qty = litemsInCart;
      let displayQty = qty ? (qty > 99 ? '99+' : qty) : null;
      clearTimeout(this.timeout);

      this.setState(
        {
          qty: qty,
          displayQty: displayQty,
          animate: true
        },
        function() {
          if (this.state.qty >= 1) {
            this.animationTimeout();
          }
        }
      );
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timeout);
  }

  state = {
    qty: 0,
    displayQty: null,
    animate: false
  };

  animationTimeout = () => {
    let that = this;
    this.timeout = setTimeout(function() {
      that.setState({
        animate: false
      });
    }, 100);
  };

  render() {
    let { qty, displayQty, animate } = this.state;
    let qtyClasses = [];

    if (qty > 0) {
      qtyClasses.push('show');

      if (animate) {
        qtyClasses.push('pop');
      }
    }

    return (
      <IconWrapper>
        <Cart />
        <Qty className={qtyClasses.join(' ')} qty={qty}>
          {qty}
        </Qty>
      </IconWrapper>
    );
  }
}

export default CartIcon;
