import React, { Fragment } from 'react';
import styled from 'react-emotion';
import { useTracker } from '@jetshop/core/analytics/Analytics';
import { trackCartCheckoutEvent } from '@jetshop/core/analytics/tracking';
import ChannelContext from '@jetshop/core/components/ChannelContext';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import t from '@jetshop/intl';
import Image from '@jetshop/ui/Image/Image';
import { Price } from '@jetshop/ui/Price';
import Button from '../../ui/Button';
import { ReactComponent as ArrowRight } from '../../../svg/arrow-circle-right.svg';
import CloseBtn from '../../ui/CloseBtn';
import cartQuery from '../../Cart/CartQuery.gql';
import HelloRetailRecommendations from '../../HelloRetail';
import Link from '../../utility/Link';

const Container = styled('aside')`
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.above.sm} {
    width: 320px;
  }
  ${({ theme }) => theme.below.sm} {
    width: 100%;
  }
`;

const ProductImageWrapper = styled('div')`
  width: 60px;
  margin-right: 10px;

  div {
    font-size: 0px;
  }
`;

const ProductCheckoutContainer = styled('div')`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  padding: 15px;

  a {
    text-decoration: none;
    :hover {
      opacity: 0.8;
      text-decoration: none;
      color: white;
    }
  }
`;

const Product = styled('div')`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const ProductDetails = styled('div')`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 100%;
  padding-right: 15px;
  color: ${({ theme }) => theme.colors.black};
`;

const Header = styled('h3')`
  margin-bottom: 15px;
  font-weight: 700;
  ${({ theme }) => theme.fontSizes.description}
`;

const ProductName = styled('p')`
  margin-bottom: 5px;
  ${({ theme }) => theme.fontSizes.description}
  color: #000;
`;

const CheckoutBtn = styled(Button)`
  &.keep-shopping {
    background: #5e85ff !important;
    margin: 10px auto 0px;
  }
  margin: 20px auto 0px;
  border-radius: 40px !important;
  padding: 1.25rem 1rem !important;
  font-size: 16px !important;

  &:hover {
    scale: 1.02;
  }

  svg {
    path {
      fill: ${({ theme }) => theme.colors.white} !important;
    }
  }
`;

const IconWrapper = styled('div')`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  svg {
    height: 18px;
    width: 18px;
    margin-bottom: -3px;

    g,
    mask,
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const RecommendedWrapper = styled('div')`
  margin-top: 30px;
  h2 {
    color: black;
    margin: 0;
    padding: 0;
  }
  .hello-retail-recommendations {
    margin: 0;
    padding: 0;
    > div {
      padding: 0;
      margin: 0;
    }
  }
  .product-grid {
    > div {
      width: 100%;
    }
  }
`;

const ProductLink = styled(Link)`
  text-decoration: none;
`;

const ProductToast = ({ product, cart, closeToast, selectedVariation }) => {
  const pricing = selectedVariation.product || product;
  const track = useTracker();

  return (
    <Container>
      <ProductCheckoutContainer>
        <Product>
          {product.images.length > 0 && (
            <ProductImageWrapper>
              <Image
                src={product.images[0].url}
                sizes={90}
                aspect={'1:1'}
                alt={product.images[0].alt}
              />
            </ProductImageWrapper>
          )}
          <ProductDetails>
            <Header>Tillagd i varukorgen</Header>
            <ProductLink to={product?.primaryRoute?.path}>
              <ProductName>{product.name}</ProductName>
            </ProductLink>
            <Price
              price={pricing.price}
              recommendedPrice={pricing.recommendedPrice}
              previousPrice={pricing.previousPrice}
            />
          </ProductDetails>

          <CloseBtn onClick={closeToast} />
        </Product>

        {cart && cart.externalCheckoutUrl && (
          <ChannelContext.Consumer>
            {({ selectedChannel }) => (
              <Fragment>
                <CheckoutBtn
                  anchor={true}
                  href={`${cart.externalCheckoutUrl}&channelCountry=${selectedChannel.country.code}`}
                  onClick={() => {
                    track(trackCartCheckoutEvent({ cart }));
                  }}
                >
                  {t('Check out')}
                  {/* <IconWrapper>
                    <ArrowRight />
                  </IconWrapper> */}
                </CheckoutBtn>
                <CheckoutBtn
                  anchor={true}
                  onClick={closeToast}
                  className="keep-shopping"
                >
                  {t('Keep shopping')}
                  {/* <IconWrapper>
                    <ArrowRight />
                  </IconWrapper> */}
                </CheckoutBtn>
                <RecommendedWrapper>
                  <HelloRetailRecommendations
                    cart={true}
                    closeToast={closeToast}
                    recommendationsList={[
                      {
                        title: 'Komplettera ditt köp med',
                        recommendationId: 'k6203b41622cae73d2f62ef9e',
                        cart: cart.items
                      }
                    ]}
                  />
                </RecommendedWrapper>
              </Fragment>
            )}
          </ChannelContext.Consumer>
        )}
      </ProductCheckoutContainer>
    </Container>
  );
};
const ProductToastWrapper = props => (
  <CartProvider query={cartQuery}>
    {({ data }) => <ProductToast {...props} cart={data && data.cart} />}
  </CartProvider>
);

export default ProductToastWrapper;
