import React from 'react';
import Helmet from 'react-helmet-async';
import styled from 'react-emotion';
import Loadable from 'react-loadable';
import { Route, Switch } from 'react-router-dom';
import DynamicRoute from '@jetshop/core/components/DynamicRoute';
import CustomFont from '@jetshop/core/components/Fonts/CustomFont';
import GenericError from '@jetshop/ui/ErrorBoundary/Generic';
import LoadingBar from '@jetshop/ui/Loading/LoadingBar';
import ModalProvider from '@jetshop/ui/Modal/ModalProvider';
import ModalRoot from '@jetshop/ui/Modal/ModalRoot';
import ScrollRestorationHandler from '@jetshop/ui/ScrollRestorationHandler';
import PaginationProvider from '@jetshop/core/components/Pagination/PaginationProvider';
import { useShopConfig } from '@jetshop/core/hooks/useShopConfig';
import loadFontCss from '../fonts/loadFontCss';
import Container from './Layout/Container';
import Footer from './Layout/Footer/Footer';
import Header from './Layout/Header/Header';
import LoadingPage from './LoadingPage';
import ProductPreviewQuery from './ProductPreviewQuery.gql';
import CategoryPreviewQuery from './CategoryPreviewQuery.gql';
import routeQuery from './RouteQuery.gql';
import { fonts, theme } from './Theme';
import { AddwishProvider } from '@jetshop/flight-addwish';

const NotFound = Loadable({
  loader: () => import('./NotFoundPage'),
  loading: LoadingPage
});

const LogInPage = Loadable({
  loader: () => import('./Auth/LogInPage'),
  loading: LoadingPage
});

const PreviewRoute = Loadable({
  loader: () => import('@jetshop/core/components/DynamicRoute/PreviewRoute'),
  loading: LoadingPage
});

const NavTreePage = Loadable({
  loader: () => import('./NavigationTree/NavTree'),
  loading: LoadingPage
});

const LoadableStartPage = Loadable({
  loader: () => import('./StartPage/StartPage'),
  loading: LoadingPage
});

const LoadableProductPage = Loadable({
  loader: () => import('./ProductPage/ProductPage'),
  loading: LoadingPage
});

const LoadableCategoryPage = Loadable({
  loader: () => import('./CategoryPage/CategoryPage'),
  loading: LoadingPage
});

const LoadableInspirationOverview = Loadable({
  loader: () => import('./Inspiration/InspirationOverviewHandler'),
  loading: LoadingPage
});

const LoadableInspiration = Loadable({
  loader: () => import('./Inspiration/Single/InspirationPageHandler'),
  loading: LoadingPage
});

const LoadableContentPage = Loadable({
  loader: () => import('./ContentPage/ContentPageHandler'),
  loading: LoadingPage
});

const LoadableSearchPage = Loadable({
  loader: () => import('./SearchPage/SearchPage'),
  loading: LoadingPage
});

const LoadableSignUpPage = Loadable({
  loader: () => import('./Auth/Signup/SignUpPage'),
  loading: LoadingPage
});

const ForgotPassword = Loadable({
  loader: () => import('./Auth/ForgotPassword'),
  loading: LoadingPage
});

const ResetPassword = Loadable({
  loader: () => import('./Auth/ResetPassword'),
  loading: LoadingPage
});

const LoadableContetPage = Loadable({
  loader: () => import('./ContentPage/ContentPage'),
  loading: LoadingPage
});

const Main = styled('main')`
  overflow: hidden;
  background-color: ${theme.colors.standardBackground};
`;

const ConditionalCategoryPage = props => (
  <Switch>
    <Route
      path="/inspiration"
      exact
      render={() => <LoadableInspirationOverview {...props} />}
    />
    <Route
      path="/inspiration/:path"
      render={() => <LoadableInspiration {...props} />}
    />
    <Route render={() => <LoadableCategoryPage {...props} />} />
  </Switch>
);

function Shop() {
  const {
    routes: { church, school }
  } = useShopConfig();

  return (
    <GenericError>
      <ModalProvider>
        <AddwishProvider>
          <Container fullWidth>
            <LoadingBar />
            {/* <CustomFont primaryFont={fonts.primary} injectCss={loadFontCss} /> */}
            <Helmet titleTemplate="%s - Nya Musik" defaultTitle="Nya Musik" />
            <Header />
            <Main>
              <PaginationProvider defaultProductsPerPage={20}>
                <Switch>
                  <Route
                    path={church.path}
                    render={() => <LoadableContetPage id={church.id} />}
                  />
                  <Route
                    path={school.path}
                    render={() => <LoadableContetPage id={school.id} />}
                  />
                  <Route exact path="/" component={LoadableStartPage} />
                  <Route path="/search" component={LoadableSearchPage} />
                  <Route path="/sokresultat" render={() => null} />
                  <Route path="/signup" component={LoadableSignUpPage} />
                  <Route path="/login" component={LogInPage} />
                  <Route path="/tree" component={NavTreePage} />
                  <Route
                    exact
                    path="/forgot-password"
                    component={ForgotPassword}
                  />
                  <Route
                    path="/reset-password/:token"
                    component={ResetPassword}
                  />
                  <Route
                    path="/preview"
                    render={props => (
                      <PreviewRoute
                        productPage={LoadableProductPage}
                        productQuery={ProductPreviewQuery}
                        categoryQuery={CategoryPreviewQuery}
                        categoryPage={LoadableCategoryPage}
                        StartPage={LoadableStartPage}
                        {...props}
                      />
                    )}
                  />
                  <DynamicRoute
                    routeQuery={routeQuery}
                    productPage={LoadableProductPage}
                    categoryPage={ConditionalCategoryPage}
                    contentPage={LoadableContentPage}
                    notFoundPage={NotFound}
                    LoadingPage={LoadingPage}
                  />
                </Switch>
              </PaginationProvider>
            </Main>
            <Footer />
            <ModalRoot />
            <ScrollRestorationHandler
              ignoreForRouteTypes={['sortOrderChange', 'filterChange']}
            />
          </Container>
        </AddwishProvider>
      </ModalProvider>
    </GenericError>
  );
}

export default Shop;
