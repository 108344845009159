import React, { Component, Fragment } from 'react';
import styled from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';
import { Price } from '@jetshop/ui/Price';
import Quantity from './Quantity';
import AddToCartButton from './AddToCartButton';
import QuantityContainer from '../QuantityContainer';
import NotifyWhenBack from '../StockStatus/NotifyWhenBack';
import StockStatusIndicator from '../StockStatus/StockStatusIndicator';

const QuantityButtonWrapper = styled('div')`
  position: relative;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-top: 15px;

  ${({ theme }) => theme.above.md} {
    margin-top: 0px;
    justify-content: flex-end;
  }

  //StockStatus
  > p {
    ${({ theme }) => theme.below.md} {
      position: absolute;
      bottom: 59px;
      left: 0px;
    }
  }
`;

const PriceWrapper = styled(QuantityButtonWrapper)`
  margin-bottom: 45px;

  @media (min-width: 410px) {
    margin-bottom: 30px;
  }

  ${({ theme }) => theme.above.md} {
    margin-bottom: 10px;
  }
`;

const StyledPrice = styled(Price)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0px 20px 0px 0px !important;
  text-align: right;
  ${({ theme }) => theme.fontSizes.heading}
  line-height: 1;
  letter-spacing: 0.5px;
  font-weight: 700;

  ${Price.New} {
    color: red;
  }

  ${Price.Old} {
    ${({ theme }) => theme.fontSizes.regular}
  }
`;

export default class AddToCartWrapper extends Component {
  render() {
    const { product, selectedVariation, stockStatus } = this.props;

    if (stockStatus.status === 'notifyWhenBack') {
      return (
        <NotifyWhenBack
          text={stockStatus.text}
          articleNumber={(selectedVariation || product).articleNumber}
        />
      );
    } else {
      return (
        <QuantityButtonWrapper>
          <QuantityContainer>
            {({ quantity, setQuantity }) => (
              <Fragment>
                <StockStatusIndicator
                  status={stockStatus.status}
                  text={stockStatus.text}
                />

                <PriceWrapper>
                  <StyledPrice
                    price={product.price}
                    previousPrice={product.previousPrice}
                  />

                  <Above breakpoint="md">
                    {matches =>
                      matches ? (
                        <Quantity
                          quantity={quantity}
                          handleChange={value => setQuantity(value)}
                        />
                      ) : null
                    }
                  </Above>
                </PriceWrapper>

                <AddToCartButton
                  selectedVariation={selectedVariation}
                  quantity={quantity}
                  product={product}
                  inStock={stockStatus.status === 'inStock'}
                />
              </Fragment>
            )}
          </QuantityContainer>
        </QuantityButtonWrapper>
      );
    }
  }
}
