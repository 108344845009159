import React from 'react';
import styled from 'react-emotion';
import Gallery from '@jetshop/ui/Gallery/Gallery';
import Badges from './Badges';

const Wrapper = styled('div')`
  display: flex;
  flex-direction: column;

  .image-gallery-content {
    ${({ theme }) => theme.below.md} {
      max-width: 450px;
      margin: 0 auto;
    }
  }

  .image-gallery-slide-wrapper {
    z-index: 0;
  }

  .image-gallery-thumbnails-container {
    max-height: 100%;
  }

  .image-gallery-slide {
    background-color: transparent !important;
  }

  .image-gallery-thumbnail {
    position: relative;
    padding: 10px !important;
    border: 0px !important;

    &::after {
      display: block;
      content: '';
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: ${({ theme }) => theme.colors.black};
      opacity: 0;
      transition: all 250ms ease;
    }

    &.active {
      &::after {
        opacity: 0.2;
      }
    }
  }

  .image-gallery-thumbnail-inner {
    font-size: 0px !important;
  }

  .image-gallery-slide-wrapper.left {
    width: calc(100% - 102px);

    .image-gallery-image {
      background-color: ${({ theme }) => theme.colors.grey};
      background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.colors.grey},
        ${({ theme }) => theme.colors.lightGrey}
      );
    }
  }

  .image-gallery-image {
    padding: 15px;
    font-size: 0px;
  }

  .image-gallery-thumbnails-wrapper.left {
    margin-right: 0px;
    width: 102px;

    a {
      margin-bottom: 2px;
      background-color: ${({ theme }) => theme.colors.grey};
      background: linear-gradient(
        to bottom,
        ${({ theme }) => theme.colors.grey},
        ${({ theme }) => theme.colors.lightGrey}
      );
    }
  }

  .image-gallery.fullscreen-modal {
    background: rgba(0, 0, 0, 0.9);
    z-index: 299;

    .image-gallery-content.fullscreen {
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      margin: auto;
      background: transparent;
    }

    .image-gallery-slide-wrapper,
    .image-gallery-swipe,
    .image-gallery-slides,
    .image-gallery-slide {
      width: 100%;
      height: 100%;

      .image-gallery-image {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: transparent;
        background: transparent;

        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
`;

export default function ImageContainer({
  images,
  thumbnailPosition,
  backgroundColor,
  badges,
  ...rest
}) {
  return (
    <Wrapper {...rest}>
      <Gallery
        images={images}
        thumbnailPosition={thumbnailPosition}
        backgroundColor={backgroundColor}
        aspect={'1:1'}
        sizes={600}
        fullScreenSizes={600}
      >
        <Badges badges={badges} />
      </Gallery>
    </Wrapper>
  );
}
