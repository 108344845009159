import React, { Fragment } from 'react';
import CartProvider from '@jetshop/core/components/Query/CartProvider';
import get from 'lodash.get';
import styled from 'react-emotion';
import { DrawerTrigger } from '@jetshop/ui/Modal/Drawer/DrawerTrigger';
import CartIcon from './CartIcon';
import cartQuery from './CartQuery.gql';

const Button = styled('button')`
  display: block;
  padding: 0;
  background: transparent;
  color: inherit;
  border: 0;
  outline: none;
  overflow: visible;

  &:hover {
    color: ${({ theme }) => theme.colors.black};
  }
`;

class CartButton extends React.PureComponent {
  render() {
    const { className } = this.props;

    return (
      <Fragment>
        <CartProvider query={cartQuery}>
          {result => {
            // Set items in cart to the API result.
            // If the result is undefined, fall back to 0

            const itemsInCart = get(result, 'data.cart.totalQuantity', 0);

            return (
              <DrawerTrigger
                preventOverflow={true}
                id="cart-drawer"
                coverStyles={{
                  zIndex: 15,
                  background: 'rgba(241,241,241,0.5)'
                }}
              >
                {drawer => (
                  <Button
                    className={className}
                    data-testid="header-cart"
                    onClick={
                      drawer.isOpen ? drawer.hideTarget : drawer.showTarget
                    }
                  >
                    <CartIcon itemsInCart={itemsInCart} />
                  </Button>
                )}
              </DrawerTrigger>
            );
          }}
        </CartProvider>
      </Fragment>
    );
  }
}

export default CartButton;
