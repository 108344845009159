import React, { useContext, useState } from 'react';
import styled from 'react-emotion';
import { toast } from 'react-toastify';
import AddToCart from '@jetshop/core/components/Mutation/AddToCart';
import { CartIdContext } from '@jetshop/core/components/Cart/CartIdContext';
import { addToCartSuccess } from '@jetshop/core/components/Mutation/AddToCart/addToCartUtils';
import ProductToast from './ProductToast';
import CartQuery from '../../Cart/CartQuery.gql';
import { addToCart } from '../../Cart/addToCart.gql';
import addToCartMutation from '../../Cart/addToCart.gql';
import Button from '../../ui/Button';
import { theme } from '../../Theme';
import { useMutation } from '@apollo/react-hooks';
import AddToCartComments from './AddToCartComments';
import { BANNER_PRODUCT_IDS } from '../../utility/staticData';

const StyledButton = styled(Button)`
  max-width: 100% !important;
  height: 58px;
  padding: 20px 30px;
  font-weight: 700;
  font-family: ${theme.fonts.primary};
  border-radius: 40px !important;
  text-transform: inherit !important;

  &:hover {
    scale: 1.01;
    opacity: 0.8;
    text-decoration: none;
    color: white;
  }

  ${({ theme }) => theme.above.md} {
    height: 67px;
    padding: 30px;
  }

  ${({ theme }) => theme.below.md} {
    margin-top: 0.75rem;
    margin-bottom: ${props => (props?.showCommentField ? '2.5rem' : 0)};
  }
`;

const AddToCartButton = ({
  product,
  selectedVariation,
  quantity = 1,
  inStock,
  title,
  className
}) => {
  const { cartId, setCartId } = useContext(CartIdContext);
  const [comments, setComments] = useState([]);
  const showCommentField = BANNER_PRODUCT_IDS.some(
    ID => ID == product?.articleNumber
  );

  const [addProductToCartMutation] = useMutation(addToCart, {
    onCompleted: data => {
      addToCartSuccess({ cartId, setCartId })({ data });
      toast(
        <ProductToast
          selectedVariation={selectedVariation ?? product}
          product={product}
        />,
        {}
      );
    }
  });

  return (
    <AddToCart
      cartQuery={CartQuery}
      cartMutation={addToCartMutation}
      // onAddoTCartInit={selectedVariation =>
      //   toast(
      //     <ProductToast
      //       selectedVariation={selectedVariation}
      //       product={product}
      //     />,
      //     {}
      //   )
      // }
    >
      {(addToCart, missingOption, hasMissingOption, ...rest) => {
        let buttonTitle = hasMissingOption
          ? 'Välj typ först'
          : title || 'Lägg till i kundvagnen';
        buttonTitle = inStock ? buttonTitle : 'Slut i lager';

        return (
          <>
            <StyledButton
              showCommentField={showCommentField}
              className={className}
              loading={false}
              loadingText={'Lägger till produkt...'}
              disabled={
                hasMissingOption ||
                !inStock ||
                (showCommentField && comments.length == 0)
              }
              css={(hasMissingOption || !inStock) && { opacity: 0.5 }}
              onClick={() =>
                addProductToCartMutation({
                  variables: {
                    input: {
                      cartId,
                      articleNumber:
                        selectedVariation?.articleNumber ??
                        product?.articleNumber,
                      quantity: quantity,
                      comments: comments
                    }
                  }
                })
              }
              data-testid="add-to-cart"
            >
              {buttonTitle}
            </StyledButton>
            {showCommentField && (
              <AddToCartComments
                comments={comments}
                setComments={setComments}
              ></AddToCartComments>
            )}
          </>
        );
      }}
    </AddToCart>
  );
};

export default AddToCartButton;
