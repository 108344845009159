import React from 'react';
import UIButton from '@jetshop/ui/Button';
import styled, { css } from 'react-emotion';
import Link from '../utility/Link';
import Spinner from './Spinner';

const secondaryStyle = theme => css`
  background-color: ${theme.colors.grey};
  background: linear-gradient(
    to bottom right,
    ${theme.colors.grey},
    ${theme.colors.lightGrey}
  );
  color: ${theme.colors.black};
`;

const disabledStyle = () => css`
  opacity: 0.5;
  cursor: default;
`;

export const TrendButtonStyle = ({ secondary, theme, disabled }) => css`
  width: 100%;
  padding: 15px 30px;
  border: none;
  color: ${theme.colors.white};
  background-color: ${theme.colors.steelGray};
  
  // background: linear-gradient(
  //   to right,
  //   ${theme.colors.gradientBlue},
  //   ${theme.colors.gradientPeach}
  // );
  
  text-transform: uppercase;
  outline: 0;
  box-shadow: ${theme.boxshadow};
  transition: all 250ms ease;

  &:hover {
    text-decoration: none;
  }

  ${secondary && secondaryStyle(theme)};
  ${disabled && disabledStyle(theme)};
`;

const Button = styled(UIButton)`
  ${TrendButtonStyle}
`;

const IconButtonWrapper = styled('div')`
  button {
    position: relative;
    width: 100%;
    height: 0px;
    padding: 0px;
    padding-bottom: 100%;
    background: linear-gradient(
      to bottom right,
      ${({ theme }) => theme.colors.gradientBlue},
      ${({ theme }) => theme.colors.gradientPeach}
    );
    box-shadow: ${({ theme }) => theme.boxshadow};
  }

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 60%;
    height: 60%;
    transform: translate(-50%, -50%);

    g,
    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

export const TrendLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  ${TrendButtonStyle};
`;

export const IconButton = ({ children, className, ...props }) => (
  <IconButtonWrapper className={className}>
    <Button {...props}>{children}</Button>
  </IconButtonWrapper>
);

const ButtonWithLoading = ({ loading, loadingText, ...props }) =>
  loading ? (
    <Button>
      {loadingText}
      <Spinner />
    </Button>
  ) : (
    <Button {...props} />
  );

export default ButtonWithLoading;
