const DEFAULT_MESSAGE_FROM_BANNERTYPE = {
  INFO: 'JUST NU HÖG BELASTNING, KAN TA LÄNGRE TID ÄN VÄNTAT',
  ALERT: 'STORA DRIFTSPROBLEM, LÅNGA ORDERTIDER',
  CAMPAIGN: 'JULKAMPANJEN ÄR IGÅNG!'
};

const TOP_BANNER_COLORS = {
  ORANGE: '#F0BC62',
  RED: '#FC393E',
  LIGHT_BLUE: '#86A9BD'
};

export const adaptTopBannerItemsData = topBannerItems => {
  const bannerMessage = topBannerItems?.properties[0]?.value?.string ?? null;
  const bannerType = topBannerItems?.properties[2]?.value?.string ?? null;

  const constuctedBannerData = {
    message: bannerMessage,
    msgColor: null,
    bgColor: null,
    showIcon: null
  };

  switch (bannerType) {
    case 'Information':
      constuctedBannerData.msgColor = 'black';
      constuctedBannerData.bgColor = TOP_BANNER_COLORS.ORANGE;
      constuctedBannerData.showIcon = true;
      break;
    case 'Alert':
      constuctedBannerData.msgColor = 'white';
      constuctedBannerData.bgColor = TOP_BANNER_COLORS.RED;
      constuctedBannerData.showIcon = true;
      break;
    case 'Campaign':
      constuctedBannerData.msgColor = 'white';
      constuctedBannerData.bgColor = TOP_BANNER_COLORS.LIGHT_BLUE;
      constuctedBannerData.showIcon = false;
      break;
    default:
  }

  if (!bannerMessage) {
    if (bannerType) {
      switch (bannerType) {
        case 'Information':
          constuctedBannerData.message = DEFAULT_MESSAGE_FROM_BANNERTYPE.INFO;
          break;
        case 'Alert':
          constuctedBannerData.message = DEFAULT_MESSAGE_FROM_BANNERTYPE.ALERT;
          break;
        case 'Campaign':
          constuctedBannerData.message =
            DEFAULT_MESSAGE_FROM_BANNERTYPE.CAMPAIGN;
          break;
        default:
      }
    }
  }

  return constuctedBannerData;
};
