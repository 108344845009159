import React, { Fragment } from 'react';
import styled from 'react-emotion';

const List = styled('ul')`
  display: flex;
  flex-grow: 1;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  list-style: none;

  li {
    position: relative;
    padding-left: 15px;
    margin: 2px 5px;
    font-size: 0.8rem;
    line-height: 1.2;
    color: ${({ theme }) => theme.colors.steelGray};

    ${({ theme }) => theme.above.xs} {
      margin: 2px 10px;
    }

    ${({ theme }) => theme.above.sm} {
      padding-left: 25px;
      margin: 5px 30px;
      ${({ theme }) => theme.fontSizes.description}
    }

    ${({ theme }) => theme.above.xl} {
      ${({ theme }) => theme.fontSizes.regular}
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 3px;
      width: 4px;
      height: 8px;
      border: 1px solid ${({ theme }) => theme.colors.black};
      border-width: 0px 1px 1px 0px;
      transform: translateY(-62%) rotate(45deg);

      ${({ theme }) => theme.above.sm} {
        width: 6px;
        height: 12px;
      }
    }
  }
`;

const USPList = ({ className, usps }) => {
  return (
    <Fragment>
      {usps ? (
        <List className={className}>
          {usps.map((usp, index) => (
            <li key={index}>{usp}</li>
          ))}
        </List>
      ) : null}
    </Fragment>
  );
};

export default USPList;
