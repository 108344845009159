import { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import ChannelContext from '@jetshop/core/components/ChannelContext';
// import { useShopConfig } from '@jetshop/core/hooks/useShopConfig'
import SiteSettingsQuery from './SiteSettingsQuery.gql';

export const useSiteSettingsQuery = () => {
  const { selectedChannel } = useContext(ChannelContext);
  const siteSettingsId = 1697;

  const { data, loading } = useQuery(SiteSettingsQuery, {
    variables: { id: siteSettingsId },
    options: {
      headers: {
        channelId: selectedChannel?.id
      }
    }
  });

  if (!siteSettingsId) {
    console.error('Environment variable SITE_SETTINGS_ID is not set');
  }

  function getSetting(key, type) {
    let value = undefined;

    data?.category?.data?.items?.forEach(item => {
      if (item?.type === type) {
        value = item;
      }
    });

    return value;
  }

  return [getSetting, loading];
};
