import React, { useContext } from 'react';

import styled from 'react-emotion';
import Link from '../utility/Link';
import FilterContext from '@jetshop/core/components/Filters/FilterContext';

const SubNameLinkWrapper = styled('span')`
  display: inline-block;

  span {
    display: inline-block;
    margin: 0px 3px;
  }

  a {
    color: ${({ theme }) => theme.colors.black};
  }
`;

const SubNameLink = ({ product, before }) => {
  const { subName, canonicalCategory } = product;
  const { applyListFilter } = useContext(FilterContext);

  const goToAuthor = () => {
    applyListFilter('subname', [{ value: subName }]);
  };

  let canonicalPath =
    canonicalCategory && canonicalCategory.primaryRoute
      ? canonicalCategory.primaryRoute.canonicalPath
      : null;
  let mainCategory = canonicalPath ? canonicalPath : null;
  let subNameLink =
    mainCategory && subName
      ? '/' +
        mainCategory.split('/')[1] +
        '/?list[subname][0]=' +
        encodeURI(subName)
      : null;

  return subName && subNameLink ? (
    <SubNameLinkWrapper>
      {before && <span>{before}</span>}
      <Link to={subNameLink} onClick={goToAuthor}>
        {subName}
      </Link>
    </SubNameLinkWrapper>
  ) : null;
};

export default SubNameLink;
