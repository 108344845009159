import React, { Component } from 'react';
import styled from 'react-emotion';
import { Above } from '@jetshop/ui/Breakpoints';
import { useStockStatus } from '@jetshop/ui/Stock/StockStatusContainer';
import ProductVariationProvider from '@jetshop/core/components/ProductVariationProvider/ProductVariationProvider';
import ProductImage from './ProductImage';
import QuickLook from '../QuickLook/QuickLook';
import {
  ProductPadding,
  ArtNr,
  StyledProductLink,
  Price,
  Title
} from './ItemStyles';
import SubNameLink from '../../ProductPage/SubNameLink';
import AddToCartButton from '../../ProductPage/AddToCart/AddToCartButton';
import ProductCardStockStatus from './ProductCardStockStatus';
import { theme } from '../../Theme';
import Link from '../../utility/Link';

const ProductWrapper = styled('div')`
  color: black;
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  text-align: left;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 40px;
  ${({ theme }) => theme.below.sm} {
    border-radius: 20px;
  }
  overflow: hidden;
  box-shadow: 0px 1px 15px -5px rgba(0, 0, 0, 0.28);
`;

const ProductCardImage = styled(ProductImage)`
  ${({ theme }) => theme.below.xs} {
    .imageInner {
      top: 10px;
      left: 10px;
      width: calc(100% - 20px);
      height: calc(100% - 20px);
    }
  }
`;

const Content = styled('div')`
  position: relative;
  padding: 0px 16px 16px 16px;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  gap: 0.75rem;
`;

const StyledAddToCartButton = styled(AddToCartButton)`
  border-radius: 40px;
  padding: 1.25rem 1rem !important;
  font-size: 16px;
  height: revert !important;
  font-weight: 400 !important;
  text-transform: inherit;
  margin-top: 1rem;
  transition: all 0.4s ease-out;
  background-color: ${props =>
    props.stockStatusId == '9' ||
    props.stockStatusId == '16' ||
    props.stockStatusId == '11'
      ? '#d2c56d'
      : props.stockStatusId == '13' || props.stockStatusId == undefined
      ? '#acb1b2'
      : '#757e7f'};

  &:hover {
    background-color: ${props =>
      props.stockStatusId == '9' ||
      props.stockStatusId == '16' ||
      props.stockStatusId == '11'
        ? '#dbd08a'
        : props.stockStatusId == '13' || props.stockStatusId == undefined
        ? '#acb1b2'
        : '#909798'};
    scale: 1.02;
  }

  ${theme.below.sm} {
    font-size: 14px;
  }

  ${theme.below.xxs} {
    font-size: 11px;
  }
`;

const TitleContainer = styled('div')`
  font-size: 20px;
  font-weight: 500;
  flex-grow: 1;

  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  ${theme.below.sm} {
    font-size: 16px;
  }

  ${theme.below.xxs} {
    font-size: 14px;
  }
`;

const TagsContainer = styled('div')`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  margin-top: 1rem;

  a {
    color: inherit;
  }

  .tag {
    span {
      overflow: hidden;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }

    font-size: 14px;
    padding: 5px 18px 6px 17px;
    border-radius: 40px;
    background-color: #d6cdce;
    transition: all 0.4s ease-out;
    ${theme.below.sm} {
      padding: 5px 10px 6px 10px;
      font-size: 10px;
    }
  }

  .tag-light {
    background-color: #dcdfdf;
  }

  .tag:hover {
    background-color: #dcdfdf;
    scale: 1.01;
  }
`;

const PriceStockContainer = styled(StyledProductLink)`
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
`;

class ProductCard extends Component {
  state = {
    hover: false
  };

  render() {
    const { product, className, categoryPath } = this.props;
    if (!product) {
      return null;
    }
    const {
      images,
      badges,
      name,
      subName,
      canonicalCategory,
      articleNumber,
      previousPrice,
      price
    } = product;

    let stockStatus = useStockStatus(product);
    stockStatus = {
      ...stockStatus,
      stockStatusId: product?.stockStatus?.stockStatusId,
      buyable: product?.stockStatus?.buyable
    };

    return (
      <ProductVariationProvider product={product}>
        <ProductPadding className={className}>
          <ProductWrapper
            onMouseEnter={() => this.setState({ hover: true })}
            onMouseLeave={() => this.setState({ hover: false })}
          >
            <StyledProductLink product={product} categoryPath={categoryPath}>
              <ProductCardImage
                className="images"
                images={images}
                badges={badges}
              />
            </StyledProductLink>

            <Content className="content">
              {/* <Above breakpoint="md">
                <QuickLook
                  className="quicklook"
                  visible={this.state.hover}
                  product={product}
                  categoryPath={categoryPath}
                  stockStatus={stockStatus}
                />
              </Above> */}

              <TagsContainer>
                {subName != '' && (
                  <div className="tag">
                    <SubNameLink before="" product={product}></SubNameLink>
                  </div>
                )}
                {canonicalCategory?.name && (
                  <Link to={canonicalCategory?.primaryRoute?.canonicalPath}>
                    <div className="tag tag-light">
                      {canonicalCategory.name}
                    </div>
                  </Link>
                )}
              </TagsContainer>

              <TitleContainer>
                <span>{name}</span>
              </TitleContainer>

              {/* 
              <TitleContainer>
                <CategoryLinks className="category-link" product={product} />

                <StyledProductLink
                  product={product}
                  categoryPath={categoryPath}
                >
                  <Title title={name}>{name}</Title>

                  {articleNumber && <ArtNr>Art. nr: {articleNumber}</ArtNr>}
                </StyledProductLink>
              </TitleContainer> */}

              <PriceStockContainer
                product={product}
                categoryPath={categoryPath}
              >
                <Price price={price} previousPrice={previousPrice} />
                <ProductCardStockStatus
                  stockStatus={stockStatus}
                  product={product}
                ></ProductCardStockStatus>
              </PriceStockContainer>

              <StyledAddToCartButton
                product={product}
                inStock={stockStatus.status === 'inStock'}
                title={
                  stockStatus.stockStatusId !== '9' &&
                  stockStatus.stockStatusId !== '16' &&
                  stockStatus.stockStatusId !== '11'
                    ? 'Lägg i kundvagnen'
                    : 'Förhandsbeställ'
                }
                stockStatusId={stockStatus.stockStatusId}
              ></StyledAddToCartButton>
            </Content>
          </ProductWrapper>
        </ProductPadding>
      </ProductVariationProvider>
    );
  }
}

export default ProductCard;
