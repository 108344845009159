import React from 'react';
import Image from '@jetshop/ui/Image';
import Badges from '../../ProductPage/Images/Badges';
import { imageObjConstruct } from '../../utility/functions';
import styled, { css } from 'react-emotion';

const ImageWrapper = styled('div')`
  position: relative;
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 10px;
  background-color: ${({ theme }) => theme.colors.lightCyanGray};

  .imageInner {
    position: absolute;
    top: 30px;
    left: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: calc(100% - 60px);
    height: calc(100% - 60px);
    overflow: hidden;
    font-size: 0px;
  }
`;

const badgesPositions = css`
  position: absolute;
  padding: 9% 0 0 4%;
`;

const transparentDataImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const ProductImage = ({ className, images, badges }) => {
  const imageDefaults = {
    sizes: [400, 280, 250, 300, 250],
    crop: false,
    aspect: '1:1'
  };
  const image = images[0];
  const imageArgs = imageObjConstruct(null, image, imageDefaults);

  if (!imageArgs.src) {
    imageArgs.src = transparentDataImg;
  }

  return (
    <ImageWrapper className={className}>
      <div className="imageInner">
        <Image {...imageArgs} />
      </div>
      <div className={badgesPositions}>
        <Badges badges={badges} />
      </div>
    </ImageWrapper>
  );
};

export default ProductImage;
