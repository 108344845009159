import React, { Component } from 'react';
import styled from 'react-emotion';

const UICloseBtn = styled('div')`
  position: relative;
  width: 30px;
  min-width: 30px;
  height: 30px;
  min-height: 30px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.boxshadow};
  cursor: pointer;

  &::before,
  &::after {
    display: block;
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 50%;
    height: 2px;
    border-radius: 1px;
    background-color: ${({ theme }) => theme.colors.black};
  }

  &::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }

  &::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
`;

class CloseBtn extends Component {
  handleClick = () => {
    if (this.props.onClick) {
      this.props.onClick();
    }
  };

  render() {
    const { className } = this.props;

    return (
      <UICloseBtn className={className} onClick={() => this.handleClick()} />
    );
  }
}

export default CloseBtn;
