import React from 'react';
import styled from 'react-emotion';
import CloseBtn from '../../ui/CloseBtn';
import t from '@jetshop/intl';

const LightText = styled('span')`
  color: ${({ theme }) => theme.colors.grey}};
  ${({ theme }) => theme.fontSizes.description}
  font-weight: normal;
`;

const Header = styled('header')`
  position: relative;
  padding: 10px;
  padding-right: 40px;
  margin-bottom: 10px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  text-align: left;

  h3 {
    font-weight: 700;
  }

  ${LightText} {
    display: block;
  }
`;

const CloseMenu = styled(CloseBtn)`
  position: absolute;
  bottom: 10px;
  right: 0;
`;

const Inner = styled('div')`
  width: 450px;
  max-width: 100%;
  height: 100%;
  padding: 10px;
  background: ${({ theme }) => theme.colors.white}};
  color: ${({ theme }) => theme.colors.black}};
  z-index: 999;
`;

const ItemCount = ({ count }) => (
  <LightText>
    {t(
      `{
        count, plural,
        =0 {}
        one {1 item}
        other {{count} items}
            }`,
      { count }
    )}
  </LightText>
);

export const DrawerInner = ({ children, ...rest }) => (
  <Inner {...rest}>{children}</Inner>
);

const DrawerHeader = ({ title, itemCount, onClose, className }) => {
  return (
    <Header className={className}>
      {title && <h3>{title}</h3>}

      {itemCount && itemCount !== 0 ? <ItemCount count={itemCount} /> : null}

      {onClose && <CloseMenu onClick={onClose} />}
    </Header>
  );
};

export default DrawerHeader;
