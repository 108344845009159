import React from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import FooterQuery from './FooterQuery.gql';
import get from 'lodash.get';
import FooterPartners from './FooterPartners';
import FooterIntegrityLinks from './FooterIntegrityLinks';
import Container from '../Container';
import SocialLinks from './SocialLinks';
import Link from '../../utility/Link';
import { getServer } from '../../utility/functions';

const Wrapper = styled('footer')`
  a {
    display: block;
    color: ${({ theme }) => theme.colors.black};
    text-decoration: none;
  }
`;

const WrapFooterInner = styled(Container)`
  display: flex;
  justify-content: center;
  flex-direction: row;
  flex-wrap: wrap;
`;

const FooterList = styled('div')`
  width: 100%;
  margin: 15px auto;
  text-align: center;
  order: ${props => (props.openingHours ? '1' : '2')};

  ${({ theme }) => theme.above.sm} {
    width: 50%;
    order: 1;
  }

  ${({ theme }) => theme.above.md} {
    width: auto;
    margin: 15px 30px;
    padding: 0px;
    margin-left: ${props => (props.push ? 'auto' : '30px')};
    text-align: ${props => (props.right ? 'right' : 'left')};

    &:first-of-type {
      margin-left: 0px;
    }

    &:last-of-type {
      margin-right: 0px;
    }
  }

  h3,
  h4 {
    font-weight: bold;
    text-transform: none;
  }

  h3 {
    margin-bottom: 5px;
  }
`;

const FooterListFiller = styled('div')`
  flex-grow: 1;
  order: 1;

  ${({ theme }) => theme.below.md} {
    display: none;
  }
`;

const WrapFooterEnd = styled('div')`
  margin-top: 40px;
  padding: 65px 10px 70px 10px;
  background-color: ${({ theme }) => theme.colors.lightGrey};

  ${({ theme }) => theme.above.sm} {
    padding: 65px 15px 70px 15px;
  }
`;

const Footer = () => {
  const _server = getServer();
  const id = _server.footerPageId;

  return (
    <Query variables={{ id: id }} query={FooterQuery}>
      {({ data }) => {
        let json = null;

        if (data && Object.keys(data).length) {
          let content = get(data, 'page.content');
          if (content) {
            try {
              json = JSON.parse(content.replace(/<(?:.|\n)*?>/gm, ''));
            } catch (e) {
              console.log(
                'Error with JSON data in footer. Validate json with https://jsonlint.com/'
              );
              console.log(e);
            }
          }
        }

        return json ? (
          <Wrapper>
            <WrapFooterInner maxWidth marginTop={'md'}>
              {json.menu1 ? (
                <FooterList left>
                  {json.menu1.title && (
                    <h3 className="heading-size">{json.menu1.title}</h3>
                  )}

                  <nav>
                    {json.menu1.links &&
                      json.menu1.links.map((link, i) => (
                        <Link
                          key={i}
                          to={link.to}
                          target={link.targetBlank}
                          className="regular-size"
                        >
                          {link.title}
                        </Link>
                      ))}
                  </nav>
                </FooterList>
              ) : null}

              {json.menu2 ? (
                <FooterList left>
                  {json.menu2.title && (
                    <h3 className="heading-size">{json.menu2.title}</h3>
                  )}

                  <nav>
                    {json.menu2.links &&
                      json.menu2.links.map((link, i) => (
                        <Link
                          key={i}
                          to={link.to}
                          target={link.targetBlank}
                          className="regular-size"
                        >
                          {link.title}
                        </Link>
                      ))}
                  </nav>
                </FooterList>
              ) : null}

              <FooterListFiller />

              {json.sociallinks && (
                <FooterList push right>
                  {json.sociallinks.title && (
                    <h3 className="heading-size">{json.sociallinks.title}</h3>
                  )}

                  <SocialLinks {...json.sociallinks} />
                </FooterList>
              )}

              {json.openinghours && (
                <FooterList right openingHours>
                  {json.openinghours.title && (
                    <h3 className="heading-size">{json.openinghours.title}</h3>
                  )}

                  {json.openinghours.items &&
                    json.openinghours.items.map((item, i) => (
                      <span key={i}>
                        {i !== 0 && <br />}

                        {item.title && (
                          <h4 className="regular-size">{item.title}</h4>
                        )}

                        {item.text && (
                          <p className="regular-size">{item.text}</p>
                        )}
                      </span>
                    ))}
                </FooterList>
              )}
            </WrapFooterInner>

            {json.menu3 && (
              <WrapFooterInner maxWidth>
                <FooterIntegrityLinks {...json.menu3} />
              </WrapFooterInner>
            )}

            {json.partners && (
              <WrapFooterEnd>
                <FooterPartners partners={json.partners} />
              </WrapFooterEnd>
            )}
          </Wrapper>
        ) : null;
      }}
    </Query>
  );
};

export default Footer;
