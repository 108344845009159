import React from 'react';
import styled from 'react-emotion';
import { theme as themeData } from '../../../components/Theme';
import BadgeItem from './BadgeItem';

const Wrapper = styled('div')`
  display: flex;
  gap: 0.5rem;
  flex: 1;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
`;

const handleBadgeData = badgeData => {
  return badgeData?.map(item => {
    // Define a default title, initially set to item's name
    let title = item?.name;
    let color = item?.style ? item.style : themeData.colors.darkGrey;
    return {
      title,
      color
    };
  });
};

const Badges = ({ badges }) => {
  const transformedBadges = handleBadgeData(badges);

  return (
    <Wrapper>
      {transformedBadges?.map((item, i) => (
        <BadgeItem key={i} badgeItem={item} />
      ))}
    </Wrapper>
  );
};

export default Badges;

// Mockdata
// const badgesAll = [
//   { name: "Två för 100 kr", style: "#216460" },
//   { name: "Tre för 99", style: "#B8A2A4" },
//   { name: "Testtagg", style: "#D7F261" },
//   { name: "Rea", style: "#ACC5F2" },
//   { name: "Provläs", style: "#1A7866" },
//   { name: "Populär", style: "#B47C26" },
//   { name: "Påskbok", style: "#569835" },
//   { name: "Nyheter ny", style: "#D8174C" },
//   { name: "Nyheter", style: "#34109D" },
//   { name: "Mängdrabatt", style: "#0E2EAD" },
//   { name: "Kampanjpris", style: "#4FC6AD" },
//   { name: "Förhandsbeställ ny", style: "#D35658" },
//   { name: "Förhandsbeställ", style: "#3DC385" },
//   { name: "Erbjudande", style: "#CE87E6" }
// ];
