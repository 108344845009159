import React from 'react';
import styled from 'react-emotion';
import Spinner from './ui/Spinner';

const LoadingPageWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  height: 100%;
  min-height: 400px;
  margin-top: ${({ theme }) => theme.margin.sm};
`;
const LoadingPage = () => {
  return (
    <LoadingPageWrapper>
      <Spinner />
    </LoadingPageWrapper>
  );
};

export default LoadingPage;
