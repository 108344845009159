import React, { Component } from 'react';
import styled from 'react-emotion';
import ReactModal from 'react-modal';
import MobileCategories from './MobileCategories';

const menuAniTime = 250;
const delay = 150;
const stagger = 50;

const MobileMenuModal = styled(ReactModal)`
  position: absolute;
  top: 0px;
  left: 50px;
  width: calc(100% - 50px);
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  outline: 0;
  transform: translateX(100%);
  transition: all ${menuAniTime}ms ease;

  &.ReactModal__Content--after-open {
    transform: translateX(0%);
  }

  &.ReactModal__Content--before-close {
    transform: translateX(100%);
  }
`;

class MobileNav extends Component {
  state = {
    menuOpening: false
  };

  closeMenu = () => {
    if (this.props.onToggleMenu) {
      this.props.onToggleMenu(false);
    }
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.open && this.props.open) {
      this.setState({ menuOpening: true });
      setTimeout(() => {
        this.setState({ menuOpening: false });
      }, menuAniTime);
    }
  }

  render() {
    const { categories, open, ctaLink, loginLink } = this.props;

    return (
      <MobileMenuModal
        isOpen={open}
        onRequestClose={this.closeMenu}
        closeTimeoutMS={menuAniTime}
        openTimeoutMS={menuAniTime}
        style={{
          overlay: {
            zIndex: 11,
            backgroundColor: 'rgba(0, 0, 0, 0.13)'
          }
        }}
      >
        <MobileCategories
          categories={categories.data}
          ctaLink={ctaLink}
          loginLink={loginLink}
          closeMenu={this.closeMenu}
          menuOpening={this.state.menuOpening}
        />
      </MobileMenuModal>
    );
  }
}

export default MobileNav;
