/*
 * Create an object with imge meta to use with the image component
 */
export const imageObjConstruct = (
  imgSrc = undefined,
  obj = {},
  defaults = {}
) => {
  let src = imgSrc ? imgSrc : obj ? obj.url : undefined;
  src = src ? src : obj ? obj.src : undefined;
  let imgObj = { ...defaults, ...obj, src };
  return imgObj;
};

/*
 * Get and set global vaiables
 */
var _server = {
  headerPageId: 52,
  footerPageId: 51,
  inspoCatId: 593,
  listLayoutCategories: [158]
};
export function setServer(server) {
  _server = server;
}
export function getServer() {
  return _server;
}

/*
 * Set suffix on counting text
 */
export const maybePluralize = (count, noun, suffix = 's') =>
  `${count} ${noun}${count !== 1 ? suffix : ''}`;

/*
 * Get product brand
 */
export function getBrand(product) {
  const field = product.customFields.find(({ key }) => key === 'brand');
  if (field) {
    return field.stringValue;
  } else {
    return null;
  }
}

/*
 * HTML to JSON
 */
export function HTML2JSON(content, title = 'component') {
  let json = null;
  if (content) {
    try {
      json = JSON.parse(content.replace(/<(?:.|\n)*?>/gm, ''));
    } catch (e) {
      console.log(
        `Error with JSON data in ${title}. Validate json with https://jsonlint.com/`
      );
      console.log(e);
    }
  }
  return json;
}

/*
 * searchArrayOfObjects
 */
export function scanArrayOfObjects(nameKey, myArray) {
  for (var i = 0; i < myArray.length; i++) {
    if (nameKey in myArray[i]) {
      return true;
    }
  }

  return false;
}

/*
 * Shuffle array
 */
export function shuffle(a) {
  for (let i = a.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [a[i], a[j]] = [a[j], a[i]];
  }
  return a;
}

/*
 * Make first letter uppercase and the rest lowercase
 */

export function capitalize(string) {
  return string
    ? string[0].toUpperCase() + string.slice(1).toLowerCase()
    : null;
}
