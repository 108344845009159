import { Below } from '@jetshop/ui/Breakpoints';
import React, { Component, Fragment } from 'react';
import { Query } from 'react-apollo';
import headerQuery from './headerQuery.gql';
import { topBannerMockData } from '../../utility/mockData';
import get from 'lodash.get';
import Measure from 'react-measure';
import styled, { css } from 'react-emotion';
import DesktopNav from './DesktopNavigation/DesktopNav';
import MobileNav from './MobileNavigation/MobileNav';
import CartFlyout from '../../Cart/CartFlyout';
import Container from '../Container';
import TopBanner from './TopBanner';
import UspRow from './UspRow';
import BrandNav from './BrandNav';
import CampaignRow from './CampaignRow';
import { theme } from '../../Theme';
import { getServer, setServer } from '../../utility/functions';
import ToastContainerWrapper from '../../Cart/ToastContainerWrapper';
import { ToastContainer } from 'react-toastify';
import { useSiteSettingsQuery } from '../../utility/useSiteSettings';

const Banner = styled('header')`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.colors.white};
  transition: all 250ms ease;
  z-index: 10;

  .hidden-on-scroll {
    z-index: 1;
    transition: all 250ms ease;
  }

  .visible-on-scroll {
    background-color: ${theme.colors.white};
    z-index: 2;
    transition: all 250ms ease;
  }

  ${theme.above.md} {
    &::after {
      display: block;
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 200vh;
      transition: all 250ms ease;
      background-color: ${theme.colors.lightGrey};
      opacity: 0;
      visibility: hidden;

      .desktopMenuOpen {
        opacity: 0.95;
        visibility: visible;
      }
    }
  }

  &.scrolled {
    ${theme.above.md} {
      transform: translateY(calc(-100% + 60px));
    }

    .hidden-on-scroll {
      margin-top: -35px;

      ${theme.below.md} {
        &.CampaignRow {
          margin-top: 0px;
        }
      }

      ${theme.above.sm} {
        margin-top: ${props => (props.topBannerActive ? '-44px' : '-53px')};
      }

      ${theme.above.md} {
        margin-top: ${props => (props.topBannerActive ? '-50px' : '-55px')};
      }
    }
  }

  &.scrolled-back {
    transform: translateY(0%);

    .hidden-on-scroll {
      margin-top: -35px;

      ${theme.below.md} {
        &.CampaignRow {
          margin-top: 0px;
        }
      }

      ${theme.above.sm} {
        margin-top: ${props => (props.topBannerActive ? '-44px' : '-53px')};
      }

      ${theme.above.md} {
        margin-top: ${props => (props.topBannerActive ? '-50px' : '-55px')};
      }
    }
  }

  ${theme.below.md} {
    background-color: ${theme.colors.white};

    .visible-on-scroll {
      background-color: ${theme.colors.white};
    }
  }
`;

const PlaceholderElement = styled('div')`
  padding-bottom: 95px;

  &.topBannerActive {
    padding-bottom: 131px;
  }
  &.campaignAndBannerActive {
    padding-bottom: 131px;
  }
  &.activeCampaign {
    padding-bottom: 96px;
  }

  ${theme.above.sm} {
    padding-bottom: 113px;

    &.topBannerActive {
      padding-bottom: 147px;
    }
    &.campaignAndBannerActive {
      padding-bottom: 147px;
    }
    &.activeCampaign {
      padding-bottom: 112px;
    }
  }

  ${theme.above.md} {
    padding-bottom: 204px;

    &.topBannerActive {
      padding-bottom: 239px;
    }
    &.campaignAndBannerActive {
      padding-bottom: 289px;
    }
    &.activeCampaign {
      padding-bottom: 254px;
    }
  }
  ${theme.above.xl} {
    padding-bottom: 204px;

    &.topBannerActive {
      padding-bottom: 247px;
    }
    &.campaignAndBannerActive {
      padding-bottom: 297px;
    }
    &.activeCampaign {
      padding-bottom: 262px;
    }
  }
`;

const StyledToastContainer = styled(ToastContainer)`
  ${({ theme }) => theme.above.sm} {
    width: unset;
  }
`;

const isIE11 =
  typeof window !== 'undefined' &&
  !!window.MSInputMethodContext &&
  !!document.documentMode;
const scrollY = isIE11 ? 'pageYOffset' : 'scrollY';

// ElementType, move this to separate file?
export const ELEMENT_TYPES = { TOP_BANNER: 'TopBanner' };

class Header extends Component {
  componentDidMount() {
    window.addEventListener('scresizeroll', this.handleResize);
    window.addEventListener('scroll', this.handleScroll);

    this.setState(
      {
        scrollY: window[scrollY],
        dirChangeY: window[scrollY]
      },
      () => this.handleScroll()
    );
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
    window.removeEventListener('resize', this.handleResize);
    window.removeEventListener('scroll', this.handleScroll);
  }

  state = {
    mobileMenuOpen: false,
    desktopMenuOpen: false,
    searchBarOpen: false,
    scrollY: 0,
    dirChangeY: 0,
    scrollState: 'not-scrolled',
    height: 0,
    currentHeight: 0,
    visibleHeight: 0
  };

  lockViewport = () => {
    const { mobileMenuOpen, desktopMenuOpen } = this.state;

    if (mobileMenuOpen || desktopMenuOpen) {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflow = 'initial';
    }
  };

  handleToggleMobileMenu = menuOpen => {
    this.setState(
      {
        searchBarOpen: false,
        mobileMenuOpen: menuOpen
      },
      () => this.lockViewport()
    );
  };

  handleToggleDesktopMenu = menuOpen => {
    this.setState(
      {
        desktopMenuOpen: menuOpen
      },
      () => this.lockViewport()
    );
  };

  handleToggleSearch = searchOpen => {
    this.setState(
      {
        searchBarOpen: searchOpen,
        mobileMenuOpen: false
      },
      () => this.lockViewport()
    );
  };

  handleResize = () => {
    document.getElementsByTagName('body')[0].style.overflow = 'initial';
  };

  saveHeaderHeight = visibleHeight => {
    let _server = getServer();
    _server.visibleHeaderHeight = visibleHeight;
    setServer(_server);
  };

  handleScroll = e => {
    const newPos = window[scrollY];
    const oldPos = this.state.scrollY;
    const dirChangeY = newPos > oldPos ? newPos : this.state.dirChangeY;
    const bannerHeight = this.state.height;
    const currentHeight = this.state.currentHeight;

    let scrollState =
      newPos < bannerHeight / 2
        ? 'not-scrolled'
        : dirChangeY - 200 > newPos
        ? 'scrolled-back'
        : 'scrolled';

    let visibleHeight = scrollState === 'scrolled' ? 60 : currentHeight;
    visibleHeight = visibleHeight < 60 ? 60 : visibleHeight;

    this.setState(
      {
        scrollY: newPos,
        dirChangeY: dirChangeY,
        desktopMenuOpen: false,
        scrollState: scrollState,
        visibleHeight: visibleHeight
      },
      () => {
        this.saveHeaderHeight(visibleHeight);
      }
    );
  };

  getPlaceHolderClassName = (activeTopBanner, activeCampaign) => {
    if (activeCampaign && activeTopBanner) {
      return 'campaignAndBannerActive';
    } else if (activeTopBanner && !activeCampaign) {
      return 'topBannerActive';
    } else if (!activeTopBanner && activeCampaign) {
      return 'activeCampaign';
    } else {
      // Switch to return null?
      return '';
    }
  };

  render() {
    const { result, getSettingFn } = this.props;

    const topBannerItems = getSettingFn(null, ELEMENT_TYPES.TOP_BANNER);

    const {
      mobileMenuOpen,
      desktopMenuOpen,
      searchBarOpen,
      height
    } = this.state;

    let json = null;
    if (result && result.data && Object.keys(result.data).length) {
      let content = get(result.data, 'page.content');
      if (content) {
        try {
          json = JSON.parse(content.replace(/<(?:.|\n)*?>/gm, ''));
        } catch (e) {
          console.log(
            'Error with JSON data in header. Validate json with https://jsonlint.com/'
          );
          console.log(e);
        }
      }
    }

    let topBannerActive = topBannerItems?.properties[1]?.value?.bool ?? true;

    let ctaLink = json && json.CTALink ? json.CTALink : false;

    let loginLink = undefined;

    let campaignLink = json && json.CampaginLink ? json.CampaginLink : false;

    let logo = json && json.Logo ? json.Logo : false;

    let bannerClasses = this.state.scrollState;
    if (desktopMenuOpen) bannerClasses += ' desktopMenuOpen';
    if (mobileMenuOpen) bannerClasses += ' mobileMenuOpen';

    let placeholderClassNames = this.getPlaceHolderClassName(
      topBannerActive,
      campaignLink
    );

    return (
      <Fragment>
        <Measure
          bounds
          onResize={ref => {
            let height = this.state.height;
            let currentHeight = ref.bounds.height;
            let visibleHeight = this.state.visibleHeight;

            if (height <= 0 || this.state.scrollState === 'not-scrolled') {
              height = ref.bounds.height;
            }

            if (!visibleHeight) {
              visibleHeight = currentHeight;
            }

            this.setState(
              {
                height: height,
                currentHeight: currentHeight,
                visibleHeight: visibleHeight
              },
              () => {
                this.saveHeaderHeight(visibleHeight);
              }
            );
          }}
        >
          {({ measureRef }) => (
            <Banner
              innerRef={measureRef}
              className={bannerClasses}
              topBannerActive={topBannerActive}
            >
              <Container fullWidth>
                {topBannerActive && (
                  <TopBanner
                    topBannerItems={topBannerItems}
                    menuOpen={false}
                    className="hidden-on-scroll"
                  />
                )}

                {json && (
                  <UspRow
                    menuOpen={false}
                    className="hidden-on-scroll"
                    usps={json.USPS}
                    ctaLink={ctaLink}
                  />
                )}

                <Container className="visible-on-scroll">
                  <BrandNav
                    logo={logo}
                    loginLink={loginLink}
                    onToggleMenu={menuOpen =>
                      this.handleToggleMobileMenu(menuOpen)
                    }
                    menuOpen={false}
                    onToggleSearch={searchOpen =>
                      this.handleToggleSearch(searchOpen)
                    }
                    searchOpen={searchBarOpen}
                  />

                  <Below breakpoint="md">
                    {matches =>
                      matches ? (
                        <MobileNav
                          categories={result}
                          ctaLink={ctaLink}
                          loginLink={loginLink}
                          campaginLink={campaignLink}
                          onToggleMenu={menuOpen =>
                            this.handleToggleMobileMenu(menuOpen)
                          }
                          open={mobileMenuOpen}
                        />
                      ) : (
                        <DesktopNav
                          categories={result}
                          onToggleMenu={menuOpen =>
                            this.handleToggleDesktopMenu(menuOpen)
                          }
                        />
                      )
                    }
                  </Below>
                </Container>

                {campaignLink && (
                  <CampaignRow
                    menuOpen={false}
                    className="hidden-on-scroll"
                    link={campaignLink}
                  />
                )}
              </Container>
            </Banner>
          )}
        </Measure>

        <CartFlyout />

        <PlaceholderElement className={placeholderClassNames} />

        <ToastContainerWrapper>
          <StyledToastContainer
            toastClassName={css`
              padding: 0px;
              cursor: auto;
              font-family: inherit;
            `}
            autoClose={false}
            hideProgressBar
            closeButton={false}
            closeOnClick={false}
          />
        </ToastContainerWrapper>
      </Fragment>
    );
  }
}

export default props => {
  const _server = getServer();
  const id = _server.headerPageId;
  const [getSetting, loading] = useSiteSettingsQuery();

  return (
    <Query variables={{ levels: 1, id: id }} query={headerQuery}>
      {result => <Header result={result} getSettingFn={getSetting} />}
    </Query>
  );
};
