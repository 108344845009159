import React, { Fragment } from 'react';
import styled from 'react-emotion';

const Row = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const TopTitle = styled('h3')`
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  text-align: center;

  &::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 150px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.black};
    transform: translate(-50%, -50%);
  }
`;

const Title = styled('h2')`
  width: 100%;
  text-align: center;
  font-weight: 700;
`;

const TitleRow = ({ className, title, topTitle }) => (
  <Fragment>
    {title && (
      <Row className={className}>
        {topTitle && <TopTitle>{topTitle}</TopTitle>}
        <Title className="hero-size">{title}</Title>
      </Row>
    )}
  </Fragment>
);

export default TitleRow;
