import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from 'emotion-theming';
import { injectGlobal } from 'react-emotion';
import { baseTheme } from '@jetshop/ui/BaseTheme';

/** To define your own breakpoints, uncomment and edit the below. */
import { createBreakpointHelpers } from '@jetshop/ui/utils/breakpoints';
export const labels = ['xxs', 'xs', 'sm', 'md', 'lg', 'xl'];
const breakpoints = ['440px', '540px', '768px', '992px', '1200px', '1400px'];

Object.assign(baseTheme, {
  labels,
  breakpoints,
  ...createBreakpointHelpers(labels, breakpoints)
});

//Add colors to theme
const colors = {
  ...baseTheme.colors,
  red: '#ff0000',
  green: '#6d7d6a',
  yellow: '#e2a300',
  black: '#1d1d1d',
  grey: '#bdbdbd',
  lightGrey: '#f1f1f1',
  lightGrey2: '#acb1b2',
  peach: '#ffaa72',
  gradientPeach: '#ffc7b6',
  gradientBlue: '#5e85ff',
  gradientBlack1: '#17181b',
  gradientBlack2: '#40454d',
  // Updated colors(New theme)
  steelGray: '#757E7F',
  lightSilverGray: 'F9F9F9', // BG-color main-content
  lightCyanGray: '#DCDFDF',
  mustardGold: '#D2C56D',
  slateBlue: '#5673A0',
  warmOrange: '#EA5B1D',
  standardBackground: '#f9f9f9',
  darkGrey: '#767e7f'
};

//Add boxshadow to theme
const boxshadow = '0px 5px 5px 0px rgba(0, 0, 0, 0.1)';

//Add margin to theme
//Used to space components
const mqSizes = (sizes = ['25px']) => {
  /* sizes array of font-sizes that matches number of breakpoints small to large */

  //Set fallback value
  let match = null;

  if (typeof window !== 'undefined') {
    //Loop trrough breakpoints and match with size
    match = Object.values(baseTheme.below)
      .map((maxWidth, i) => {
        const mediaquery = maxWidth.replace('@media ', '');

        if (window.matchMedia(mediaquery).matches) {
          return sizes[i];
        } else {
          return false;
        }
      })
      .filter(Boolean);

    //Save best match
    match = match[0];
  }

  match = match ? match : sizes.reverse()[0];
  return match;
};

const margin = {
  xs: () => mqSizes(['10px', '15px', '15px', '25px', '25px']),
  sm: () => mqSizes(['15px', '15px', '30px', '30px', '50px']),
  md: () => mqSizes(['50px', '50px', '100px', '100px', '100px']),
  lg: () => mqSizes(['100px', '100px', '150px', '150px', '150px']),
  xl: () => mqSizes(['100px', '100px', '150px', '200px', '200px'])
};

const radius = {
  md: '28px'
};

//FontSizes
const mega = `
  font-size: 3.2rem;
  line-height: 1.25;
  letter-spacing: 0.5px;

  ${baseTheme.above.md} {
    font-size: 6rem;
    line-height: 1;
    letter-spacing: 0.9px;
  }
`;

const superMega = `
  font-size: 3.2rem;
  line-height: 1.25;
  letter-spacing: 0.5px;

  ${baseTheme.above.md} {
    font-size: 6rem;
    line-height: 1;
    letter-spacing: 0.9px;
  }

  ${baseTheme.above.xl} {
    font-size: 9rem;
    line-height: 1;
    letter-spacing: 1.4px;
  }
`;

const hero = `
  font-size: 2.6rem;
  line-height: 1.38;
  letter-spacing: 0.5px;

  ${baseTheme.above.md} {
    font-size: 3.2rem;
    line-height: 1.25;
    letter-spacing: 0.5px;
  }
`;

const heading = `
  font-size: 2.6rem;
  line-height: 1.38;
  letter-spacing: 0.5px;
`;

const subHeading = `
  font-size: 1.8rem;
  line-height: 1.39;
  letter-spacing: 1px;
  text-transform: uppercase;
`;

const regular = `
  font-size: 1.8rem;
  line-height: 1.39;
  letter-spacing: 0.26px;
`;

const description = `
  font-size: 1.4rem;
  line-height: 1.5;
  letter-spacing: 0.2px;
`;

const fontSizes = {
  description: description,
  regular: regular,
  subHeading: subHeading,
  heading: heading,
  hero: hero,
  mega: mega,
  superMega: superMega
};

//Add font to theme
export const fonts = {
  primary: 'sofia-pro, sans-serif'
};

// Use https://meowni.ca/font-style-matcher/ to determine fallback styles that
// most closely match your fontFamily above
const fallbackStyles = `
  overflow-x: hidden;
  font-family: system-ui;
  font-size: 1.8rem;
  line-height: 1.39;
  letter-spacing: 0.26px;
  font-weight: 400;
  visibility: visible;
`;

injectGlobal`
  body {
    ${fallbackStyles};
    font-family: "sofia-pro",sans-serif;
  }

  .st-shadow {
    box-shadow: 0px 3px 6px 0 rgba(0,0,0,0.16);
  }

  a {
    text-decoration: none;
  }

  ::-moz-selection {
    background: ${colors.gradientBlue}; 
    color: ${colors.white};  
  }

  ::selection  {
    background: ${colors.gradientBlue}; 
    color: ${colors.white};
  }

  /* 
   * apply a natural box layout model to all elements, but allowing components
   * to change 
   */
  html {
    box-sizing: border-box;

    //This reset makes it easier to use rem
    // With this reset 1rem ~ 10px, 1.2rem ~12px and so on
    font-size: 62.5%;
  }
  *, *:before, *:after {
    box-sizing: inherit;
  }
  /*
   * font-size
   */
  .super-mega-size {
    ${superMega}
  }
  .mega-size {
    ${mega}
  }
  h1,
  .hero-size {
    ${hero}
  }
  h2,
  .heading-size {
    ${heading}
  }
  h3, h4,
  .sub-heading-size {
    ${subHeading}
  }
  h5, h6,
  .regular-size {
    ${regular}
  }
  .description-size {
    ${description}
  }
  .ReactModal__Overlay--after-open {
    overflow: hidden;
  }

  .embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; }
  .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }
`;

//Define theme
export const theme = {
  ...baseTheme,
  colors,
  boxshadow,
  margin,
  fontSizes,
  fonts,
  radius
};

const Theme = ({ children }) => {
  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};

Theme.propTypes = {
  children: PropTypes.node
};
export default Theme;
