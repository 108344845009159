import React, { Component } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import query from './ProductsQuery.gql';
import get from 'lodash.get';
import { Above } from '@jetshop/ui/Breakpoints';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Container from '../Layout/Container';
import Slider from 'react-slick';
import ProductGrid from '../ui/ProductGrid';
import { TrendLink, TrendButtonStyle } from '../ui/Button';
import TitleRow from '../ui/TitleRow';
import { ReactComponent as ArrowRight } from '../../svg/arrow-circle-right.svg';
import ProductCard from '../ui/ProductItems/ProductCard';
import 'slick-carousel/slick/slick.css';
import { FastArrowDown } from 'iconoir-react';
import { theme } from '../Theme';
import Link from '../utility/Link';

const Row = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTrendLink = styled(TrendLink)`
  margin-top: 20px;
  width: auto;
`;

const StyledCategoryLink = styled(CategoryLink)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  ${TrendButtonStyle}
  width: auto;
`;

const IconWrapper = styled('div')`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  svg {
    height: 18px;
    width: 18px;
    margin-bottom: -3px;

    g,
    mask,
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const StyledProductGrid = styled(ProductGrid)`
  margin: 20px -5px;
`;

const StyledProductCard = styled(ProductCard)`
  height: 100%;
`;

const StyledSlider = styled(Slider)`
  margin: 20px -5px;
  transition: all 250m ease;

  ${({ theme }) => theme.above.sm} {
    margin: 20px 25px;
  }

  ${({ theme }) => theme.above.md} {
    margin: 20px 36px;
  }

  ${({ theme }) => theme.above.xl} {
    margin: 20px -5px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto !important;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    border: none;
    color: transparent;
    font-size: 0;
    line-height: 0;
    outline: none;
    cursor: pointer;
    transform: translate(0, -50%);
    border-radius: 50%;
    width: 40px;
    height: 40px;
    transition: all 0.4s ease-out;
    background-color: ${({ theme }) => theme.colors.darkGrey} !important;
    box-shadow: ${({ theme }) => theme.boxshadow};
    z-index: 1;

    &:hover {
      background-color: #8f9798 !important;
      scale: 0.95;
    }

    ${({ theme }) => theme.above.md} {
      width: 72px;
      height: 72px;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 10px;
      width: 10px;
      border-right: 2px solid ${({ theme }) => theme.colors.white};
      border-bottom: 2px solid ${({ theme }) => theme.colors.white};
      border-radius: 1px;
      opacity: 1;
      transform-origin: top left;
      transform: rotate(135deg) translate(-50%, -50%);

      ${({ theme }) => theme.above.md} {
        height: 16px;
        width: 16px;
        border-right: 4px solid ${({ theme }) => theme.colors.white};
        border-bottom: 4px solid ${({ theme }) => theme.colors.white};
        border-radius: 2px;
      }
    }

    &.slick-prev {
      ${({ theme }) => theme.below.sm} {
        top: auto;
        bottom: -60px;
        left: 50%;
        transform: translateX(-55px);
      }

      ${({ theme }) => theme.above.md} {
        left: -35px;
      }
    }

    &.slick-next {
      ${({ theme }) => theme.below.sm} {
        top: auto;
        bottom: -60px;
        right: 50%;
        transform: translateX(55px);
      }

      ${({ theme }) => theme.above.md} {
        right: -35px;
      }

      &::before {
        transform-origin: top left;
        transform: rotate(-45deg) translate(-50%, -50%);
      }
    }
  }
`;

const ProductWrapper = styled('div')`
  text-align: center;
  outline: 0;
  box-shadow: none;
`;

const CarouselTitel = styled('h1')`
  color: #757e7f;
  font-size: min(calc(1vw + 1.8rem), 42px);
  font-weight: 800;
  line-height: 1.23;
  display: flex;
  justify-content: center;
`;

const CarouselButton = styled('button')`
  padding: 1rem 3rem;
  background-color: #757e7f;
  border-radius: 100px;
  color: white;
  font-size: min(calc(1vw + 0.9rem), 1.8rem);
  font-weight: bold;
  transition: all 0.4s ease-out;
  display: flex;
  align-items: center;
  gap: 1rem;

  ${theme.below.sm} {
    font-size: 16px;
  }

  &:hover {
    background-color: #8f9798;
    scale: 1.02;
  }
`;

const Carousel = ({
  id,
  marginTop,
  marginBottom,
  title,
  topTitle,
  settings,
  showProducts,
  products,
  link
}) => (
  <Container id={id} maxWidth marginTop={marginTop} marginBottom={marginBottom}>
    <CarouselTitel>{topTitle || title}</CarouselTitel>
    {products && (
      <Above breakpoint="md">
        {matches =>
          matches ? (
            <StyledSlider {...settings}>
              {products.map(product => {
                return (
                  <ProductWrapper key={product.id}>
                    <StyledProductCard product={product} />
                  </ProductWrapper>
                );
              })}
            </StyledSlider>
          ) : (
            <StyledProductGrid className="product-grid">
              {products
                .filter((product, i) => {
                  return i < showProducts;
                })
                .map(product => (
                  <ProductWrapper key={product.id}>
                    <StyledProductCard product={product} />
                  </ProductWrapper>
                ))}
            </StyledProductGrid>
          )
        }
      </Above>
    )}

    {/* {products && (
      <StyledSlider {...settings}>
        {products.map(product => {
          return (
            <ProductWrapper key={product.id}>
              <StyledProductCard product={product} />
            </ProductWrapper>
          );
        })}
      </StyledSlider>
    )} */}

    {link && (
      <Row>
        {link.category ? (
          <Link to={link?.category?.primaryRoute?.path}>
            <CarouselButton>
              {link.title}
              <FastArrowDown height={'22px'} width={'22px'}></FastArrowDown>
            </CarouselButton>
          </Link>
        ) : (
          <StyledTrendLink to={link.to} secondary={1}>
            {link.title}
            <IconWrapper>
              <ArrowRight />
            </IconWrapper>
          </StyledTrendLink>
        )}
      </Row>
    )}
  </Container>
);

class ProductCarousel extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  state = {
    slidesToShow: 4
  };

  handleResize = () => {
    let slidesToShow = 4;

    if (typeof document !== 'undefined') {
      let clientWidth = document.documentElement.clientWidth;

      if (clientWidth <= 600) {
        slidesToShow = 1;
      } else if (clientWidth <= 800) {
        slidesToShow = 2;
      } else if (clientWidth <= 1200) {
        slidesToShow = 3;
      }
    }

    this.setState({
      slidesToShow: slidesToShow
    });
  };

  render() {
    let { products, categoryId, ...rest } = this.props;

    let { slidesToShow } = this.state;
    let slidesToScroll = slidesToShow > 1 ? slidesToShow - 1 : 1;

    let settings = {
      dots: false,
      infinite: products ? products.length > 3 : false,
      speed: 500,
      easing: 'ease',
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToScroll
    };

    if (products) {
      return (
        <Carousel
          {...rest}
          settings={settings}
          products={products}
          showProducts={8}
        />
      );
    } else if (categoryId) {
      return (
        <Query query={query} variables={{ id: categoryId }}>
          {({ data }) => {
            if (!data) {
              return null;
            }

            const { category } = data;
            let products = get(category, 'products.result');

            let link = {
              title: 'Utforska fler',
              category: category
            };

            //Mobile display
            let numOfProducts = products ? products.length : 0;
            const maxProducts = 4;
            const minProducts = 2;

            let showProducts = maxProducts;
            if (numOfProducts < maxProducts && numOfProducts > minProducts) {
              // ODD
              if (numOfProducts & 1) {
                numOfProducts--;
              }
            }

            return numOfProducts ? (
              <Carousel
                link={link}
                showProducts={showProducts}
                {...rest}
                settings={settings}
                products={products}
              />
            ) : null;
          }}
        </Query>
      );
    } else {
      return null;
    }
  }
}

export default ProductCarousel;
