import React from 'react';
import { Above } from '@jetshop/ui/Breakpoints';
import styled from 'react-emotion';
import USPList from '../../ui/USPList';
import { TrendLink } from '../../ui/Button';
import { ReactComponent as ChurchIcon } from '../../../svg/church-icon.svg';
import { theme } from '../../Theme';

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-end;
  background-color: ${({ theme }) => theme.colors.lightGrey};
  transition: all 250ms ease;

  ${({ theme }) => theme.below.md} {
    opacity: ${({ menuOpen }) => (menuOpen ? '0' : '1')};
    visibility: ${({ menuOpen }) => (menuOpen ? 'hidden' : 'visible')};
  }
`;

const StyledList = styled(USPList)`
  padding: ${({ ctaLink }) => (ctaLink ? '11px 280px 11px 15px' : '11px 15px')};

  ${({ theme }) => theme.above.xl} {
    padding: 11px 280px;
  }
`;

const BtnContainer = styled('div')`
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  ${theme.below.md} {
    display: none;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 270px;
    box-shadow: none;
  }
`;

const StyledChurchIcon = styled(ChurchIcon)`
  margin-bottom: 4px;
  &.space-left {
    margin-left: 1rem;
  }
`;

const UspRow = ({ menuOpen, usps, ctaLink, ...rest }) => {
  return (
    <Wrapper {...rest} menuOpen={menuOpen}>
      <StyledList usps={usps} hasCtaLink={!!ctaLink} />

      <Above breakpoint="md">
        {matches =>
          matches && ctaLink ? (
            <BtnContainer>
              <TrendLink to={ctaLink.to} target={ctaLink.targetBlank}>
                {ctaLink.title}
                <StyledChurchIcon
                  className={`${ctaLink?.title ? 'space-left' : ''}`}
                />
              </TrendLink>
            </BtnContainer>
          ) : null
        }
      </Above>
    </Wrapper>
  );
};

export default UspRow;
