import React from 'react';
import styled from 'react-emotion';

const StockStatusContainer = styled('div')`
  display: flex;
  gap: 8px;
  font-size: 12px;
  align-items: center;
`;

const parseDateFromString = inputString => {
  // Regular expression pattern to match the date format within the context
  const regex = /(\d{4}-\d{2}-\d{2})/;

  // Match the date using the regular expression
  const matches = inputString.match(regex);

  if (matches && matches.length >= 2) {
    const extractedDate = matches[1]; // The date will be in the second element of the matches array
    return extractedDate;
  } else {
    console.log('No date found in the input string.');
  }
  return '';
};

const ProductCardStockStatus = ({ stockStatus, ...props }) => {
  let color = '';
  let message = '';

  if (stockStatus?.status == 'inStock' && stockStatus?.stockStatusId == '9') {
    color = '#d2c56d';
    message = `Beställningsvara (${parseDateFromString(stockStatus?.text)})`;
  } else if (
    stockStatus?.status == 'inStock' &&
    stockStatus?.stockStatusId == '16'
  ) {
    color = '#d2c56d';
    message = stockStatus.text;
  } else if (stockStatus?.stockStatusId == '11') {
    color = '#d2c56d';
    message = 'Leverans vid REA-start 20 feb';
  } else if (stockStatus?.stockStatusId == '3') {
    color = '#db7a7a';
    message = stockStatus.text;
  } else if (
    stockStatus?.stockStatusId == '1' &&
    stockStatus?.buyable == false
  ) {
    color = '#db7a7a';
    message = 'Slutsåld';
  } else if (stockStatus?.status == 'inStock') {
    color = '#a0db7a';
    message = 'Finns i lager';
  } else {
    color = '#db7a7a';
    message = 'Tillfälligt slut';
  }

  return (
    <StockStatusContainer>
      <div
        style={{
          backgroundColor: color,
          minWidth: '14px',
          minHeight: '14px',
          borderRadius: '100px'
        }}
      ></div>
      {message}
    </StockStatusContainer>
  );
};

export default ProductCardStockStatus;
