import React from 'react';
import styled from 'react-emotion';
import { ReactComponent as ExcalamtionMarkSvg } from '../../../svg/exclamation-mark-icon.svg';
import { adaptTopBannerItemsData } from '../../utility/TransformData/topBanner';

const TOP_BANNER_HEIGHT = '35px';

const Wrapper = styled('div')`
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  background-color: ${props => props.bgColor};
  height: ${TOP_BANNER_HEIGHT};

  ${({ theme }) => theme.below.md} {
    opacity: ${({ menuOpen }) => (menuOpen ? '0' : '1')};
    visibility: ${({ menuOpen }) => (menuOpen ? 'hidden' : 'visible')};
  }
`;

const MessageContent = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  list-style: none;
`;

const ExcalamtionMarkIcon = styled(ExcalamtionMarkSvg)`
  width: 1.3rem;
  margin-right: calc(1.3rem / 2);
  margin-top: 2px;

  ${({ theme }) => theme.above.sm} {
    width: 1.6rem;
    margin-right: calc(1.6rem / 2);
  }

  path {
    stroke: ${props => props.iconColor};
  }
`;

const TopBannerMessage = styled('span')`
  position: relative;
  font-size: 0.8rem;
  line-height: 0.5;
  color: ${props => props.textColor};
  text-transform: uppercase;
  font-weight: 700;

  ${({ theme }) => theme.above.sm} {
    font-size: 1.2rem;
    line-height: 2;
  }
`;

const TopBanner = ({ topBannerItems, menuOpen, className, ...rest }) => {
  const topBannerData = adaptTopBannerItemsData(topBannerItems);
  const classes = 'TopBannerRow ' + className;
  if (menuOpen) classes += ' menuOpen';

  return (
    <Wrapper className={classes} {...rest} bgColor={topBannerData.bgColor}>
      <MessageContent role="alert" aria-live="assertive">
        {topBannerData.showIcon && (
          <ExcalamtionMarkIcon iconColor={topBannerData.msgColor} />
        )}
        <TopBannerMessage textColor={topBannerData.msgColor}>
          {topBannerData.message}
        </TopBannerMessage>
      </MessageContent>
    </Wrapper>
  );
};

export default TopBanner;
