import React, { Component } from 'react';
import styled from 'react-emotion';
import MobileLevel from './MobileLevel';

const Wrapper = styled('div')`
  position: relative;
  height: 100%;
  overflow: hidden;
`;

class MobileCategories extends Component {
  componentDidMount() {
    let { categories } = this.props;

    if (categories && categories.categories) {
      categories = categories.categories;
    }

    this.setState({
      levels: { 0: categories }
    });
  }

  state = {
    depth: 0,
    animation: 'descend',
    levels: {},
    currentCats: {}
  };

  descendSubMenu = (e, category) => {
    e.preventDefault();

    const { depth, levels, currentCats } = this.state;

    const newDepth = depth + 1;
    let newLevels = JSON.parse(JSON.stringify(levels));
    newLevels[newDepth] = category.subcategories;
    let newCats = JSON.parse(JSON.stringify(currentCats));
    newCats[newDepth] = category;

    this.setState({
      depth: newDepth,
      animation: 'descend',
      levels: newLevels,
      currentCats: newCats
    });
  };

  ascendSubMenu = e => {
    e.preventDefault();

    const { depth } = this.state;
    const newDepth = depth - 1;

    this.setState({
      depth: newDepth,
      animation: 'ascend'
    });
  };

  renderMenuLevels = () => {
    let { closeMenu, ctaLink, loginLink, menuOpening } = this.props;
    const { depth, levels, currentCats, animation } = this.state;

    let renderLevels =
      levels &&
      Object.values(levels).map((categories, i) => {
        const prevCat = currentCats[i];

        return (
          <MobileLevel
            key={i}
            depth={depth}
            current={i}
            currentCat={prevCat}
            animation={animation}
            categories={categories}
            descendSubMenu={this.descendSubMenu}
            ascendSubMenu={this.ascendSubMenu}
            closeMenu={closeMenu}
            ctaLink={ctaLink}
            loginLink={loginLink}
            menuOpening={menuOpening}
          />
        );
      });

    return Object.values(renderLevels);
  };

  render() {
    return <Wrapper>{this.renderMenuLevels()}</Wrapper>;
  }
}

export default MobileCategories;
