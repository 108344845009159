import React, { Fragment, Component } from 'react';
import styled from 'react-emotion';
import QuickLookModal from './QuickLookModal';
// import { ReactComponent as Eye } from '../../../svg/eye-icon.svg';
import { ReactComponent as Eye } from '../../../svg/eye-regular.svg';

const QuickLookBtn = styled('div')`
  position: absolute;
  bottom: 100%;
  left: 50%;
  width: calc(100% - 20px);
  max-width: 240px;
  transition: all 250ms ease;
  opacity: ${({ visible }) => (visible ? '1' : '0')};
  transform: ${({ visible }) =>
    visible ? 'translate(-50%, -50%)' : 'translate(-50%, -40%)'};

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 20px;
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.gradientBlack1};
  background: linear-gradient(
    to right,
    ${({ theme }) => theme.colors.gradientBlack1},
    ${({ theme }) => theme.colors.gradientBlack2}
  );
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  cursor: pointer;

  svg {
    height: 23px;
    width: 23px;
    margin-left: 10px;
    color: white;
/* 
    g {
      fill: ${({ theme }) => theme.colors.white};
    } */
  }
`;

class QuickLook extends Component {
  componentWillUnmount() {
    this.closeMenu();
  }

  state = {
    open: false
  };

  handleClick = () => {
    this.setState({
      open: !this.state.open
    });
  };

  closeMenu = () => {
    this.setState({
      open: false
    });
  };

  render() {
    const { open } = this.state;
    const {
      className,
      product,
      stockStatus,
      visible,
      categoryPath
    } = this.props;
    let isVisible = visible !== undefined ? visible : true;

    return (
      <Fragment>
        <QuickLookBtn
          className={className}
          visible={isVisible}
          onClick={() => this.handleClick()}
        >
          QuickLook <Eye />
        </QuickLookBtn>

        <QuickLookModal
          isOpen={open}
          product={product}
          categoryPath={categoryPath}
          stockStatus={stockStatus}
          onCloseMenu={() => this.closeMenu()}
        />
      </Fragment>
    );
  }
}

export default QuickLook;
