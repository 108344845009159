import React, { Component } from 'react';
import styled from 'react-emotion';
import { Query } from 'react-apollo';
import query from './ProductsQuery.gql';
import get from 'lodash.get';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Container from '../Layout/Container';
import Slider from 'react-slick';
import ProductGrid from '../ui/ProductGrid';
import { TrendLink, TrendButtonStyle } from '../ui/Button';
import { ReactComponent as ArrowRight } from '../../svg/arrow-circle-right.svg';
import ProductCard from '../ui/ProductItems/ProductCard';
import 'slick-carousel/slick/slick.css';

const Row = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
`;

const StyledTrendLink = styled(TrendLink)`
  margin-top: 20px;
  width: auto;
`;

const StyledCategoryLink = styled(CategoryLink)`
  display: inline-block;
  text-decoration: none;
  text-align: center;
  ${TrendButtonStyle}
  width: auto;
`;

const IconWrapper = styled('div')`
  display: inline-block;
  width: 18px;
  height: 18px;
  margin-left: 10px;

  svg {
    height: 18px;
    width: 18px;
    margin-bottom: -3px;

    g,
    mask,
    path,
    stroke {
      fill: ${({ theme }) => theme.colors.black};
    }
  }
`;

const StyledProductGrid = styled(ProductGrid)`
  margin: 20px -5px;
`;

const StyledProductCard = styled(ProductCard)`
  height: 100%;
  button {
    display: none;
  }
  .category-link {
    display: none;
  }
  .quicklook {
    display: none;
  }
  .images {
    margin-bottom: 0;
  }
  .content {
    margin-top: 0;
    padding-bottom: 3rem;
    h3 {
      font-size: 14px;
    }
  }
`;

const StyledSlider = styled(Slider)`
  margin: 20px -5px;
  transition: all 250m ease;

  ${({ theme }) => theme.above.sm} {
    margin: 20px 25px;
  }

  ${({ theme }) => theme.above.md} {
    margin: 20px 36px;
  }

  ${({ theme }) => theme.above.xl} {
    margin: 20px -5px;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto !important;

    > div {
      height: 100%;

      > div {
        height: 100%;
      }
    }
  }

  .slick-arrow {
    position: absolute;
    top: 50%;
    display: block;
    padding: 0;
    border: none;
    color: transparent;
    font-size: 0;
    line-height: 0;
    outline: none;
    cursor: pointer;
    transform: translate(0, -50%);

    border-radius: 50%;
    width: 40px;
    height: 40px;
    background-color: ${({ theme }) => theme.colors.darkGrey} !important;
    box-shadow: ${({ theme }) => theme.boxshadow};
    z-index: 1;

    ${({ theme }) => theme.above.md} {
      width: 72px;
      height: 72px;
    }

    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      height: 10px;
      width: 10px;
      border-right: 2px solid ${({ theme }) => theme.colors.white};
      border-bottom: 2px solid ${({ theme }) => theme.colors.white};
      border-radius: 1px;
      opacity: 1;
      transform-origin: top left;
      transform: rotate(135deg) translate(-50%, -50%);

      ${({ theme }) => theme.above.md} {
        height: 11px;
        width: 11px;
        border-right: 4px solid ${({ theme }) => theme.colors.white};
        border-bottom: 4px solid ${({ theme }) => theme.colors.white};
        border-radius: 2px;
      }
    }

    &.slick-prev {
      height: 40px;
      width: 40px;
      ${({ theme }) => theme.below.sm} {
        top: auto;
        bottom: 30%;
        left: 0;
        /* transform: translateX( -55px); */
      }

      ${({ theme }) => theme.above.md} {
        left: 0;
      }
    }

    &.slick-next {
      height: 40px;
      width: 40px;
      ${({ theme }) => theme.below.sm} {
        top: auto;
        bottom: 30%;
        right: 0;
        /* transform: translateX( 55px ); */
      }

      ${({ theme }) => theme.above.md} {
        right: 0;
      }

      &::before {
        transform-origin: top left;
        transform: rotate(-45deg) translate(-50%, -50%);
      }
    }
  }

  .slick-list {
    max-width: 95vw;
    width: 100%;
    margin: auto;
  }
`;

const Title = styled('h3')`
  position: relative;
  width: 100%;
  padding-bottom: 30px;
  text-align: center;
  color: black;

  &::after {
    display: block;
    content: '';
    position: absolute;
    bottom: 15px;
    left: 50%;
    width: 150px;
    height: 2px;
    background-color: ${({ theme }) => theme.colors.black};
    transform: translate(-50%, -50%);
  }
`;

const ProductWrapper = styled('div')`
  padding: 5px;
  text-align: center;
  outline: 0;
  box-shadow: none;
`;

const Carousel = ({
  id,
  marginTop,
  title,
  settings,
  showProducts,
  products,
  link,
  closeToast
}) => (
  <Container id={id} maxWidth marginTop={marginTop}>
    <Title>{title}</Title>

    {products && (
      <StyledSlider {...settings}>
        {products.map(product => {
          return (
            <ProductWrapper key={product.id} onClick={closeToast}>
              <StyledProductCard product={product} />
            </ProductWrapper>
          );
        })}
      </StyledSlider>
    )}

    {link && (
      <Row>
        {link.category ? (
          <StyledCategoryLink category={link.category} secondary={1}>
            {link.title}
            <IconWrapper>
              <ArrowRight />
            </IconWrapper>
          </StyledCategoryLink>
        ) : (
          <StyledTrendLink to={link.to} secondary={1}>
            {link.title}
            <IconWrapper>
              <ArrowRight />
            </IconWrapper>
          </StyledTrendLink>
        )}
      </Row>
    )}
  </Container>
);

class CartProductCarousel extends Component {
  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  state = {
    slidesToShow: 1
  };

  handleResize = () => {
    let slidesToShow = 1;

    if (typeof document !== 'undefined') {
      let clientWidth = document.documentElement.clientWidth;

      if (clientWidth <= 600) {
        slidesToShow = 1;
      } else if (clientWidth <= 800) {
        slidesToShow = 2;
      } else if (clientWidth <= 1200) {
        slidesToShow = 3;
      }
    }

    this.setState({
      slidesToShow: slidesToShow
    });
  };

  render() {
    let { products, categoryId, ...rest } = this.props;

    let { slidesToShow } = this.state;
    let slidesToScroll = slidesToShow > 1 ? slidesToShow - 1 : 1;

    let settings = {
      dots: false,
      infinite: true,
      speed: 500,
      easing: 'ease',
      slidesToShow: slidesToShow,
      slidesToScroll: slidesToScroll
    };

    if (products) {
      return (
        <Carousel
          {...rest}
          settings={settings}
          products={products}
          showProducts={8}
        />
      );
    } else if (categoryId) {
      return (
        <Query query={query} variables={{ id: categoryId }}>
          {({ data }) => {
            if (!data) {
              return null;
            }

            const { category } = data;
            let products = get(category, 'products.result');

            let link = {
              title: 'Utforska fler',
              category: category
            };

            //Mobile display
            let numOfProducts = products ? products.length : 0;
            const maxProducts = 4;
            const minProducts = 2;

            let showProducts = maxProducts;
            if (numOfProducts < maxProducts && numOfProducts > minProducts) {
              // ODD
              if (numOfProducts & 1) {
                numOfProducts--;
              }
            }

            return numOfProducts ? (
              <Carousel
                link={link}
                showProducts={1}
                {...rest}
                settings={settings}
                products={products}
              />
            ) : null;
          }}
        </Query>
      );
    } else {
      return null;
    }
  }
}

export default CartProductCarousel;
