import React from 'react';
import styled from 'react-emotion';
import Link from '../../utility/Link';
import SubNameLink from '../../ProductPage/SubNameLink';

const CategoryContainer = styled('div')`
  a,
  span {
    color: ${({ theme }) => theme.colors.black};
    ${({ theme }) => theme.fontSizes.description}

    ${({ theme }) => theme.below.xs} {
      font-size: 1rem;
    }
  }

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const CategoryTitle = styled(Link)`
  border: 0px;
  color: ${({ theme }) => theme.colors.black};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const CategoryLinks = ({ product, ...rest }) => {
  const { canonicalCategory } = product;

  return (
    <CategoryContainer {...rest}>
      {canonicalCategory && (
        <CategoryTitle to={canonicalCategory?.primaryRoute?.canonicalPath}>
          {canonicalCategory.name}
        </CategoryTitle>
      )}
      <SubNameLink before="-" product={product} />
    </CategoryContainer>
  );
};

export default CategoryLinks;
