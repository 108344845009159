import styled from 'react-emotion';

const stockColors = {
  inStock: '#27AE60',
  outOfStock: '#EB5757',
  notifyWhenBack: '#F2C94C'
};

const StockOrb = styled('span')`
  display: inline-block;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  transition: all 0.2s linear;
  background-color: ${({ status }) => stockColors[status]};
  margin-right: 5px;
`;

export default StockOrb;
