import React from 'react';
import styled from 'react-emotion';
import Link from '../../utility/Link.js';

const Wrapper = styled('ul')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    padding: 15px 30px;

    @media (max-width: 40rem) {
      width: 50%;
      text-align: center;
    }

    @media (max-width: 25rem) {
      width: 100%;
    }
  }

  img {
    max-height: 25px;
    margin: auto;
  }
`;

const FooterPartners = ({ partners }) => {
  return (
    <Wrapper>
      {partners &&
        partners.map((partner, i) => (
          <li key={i}>
            {partner.to ? (
              <Link to={partner.to} target={true}>
                <img src={partner.src} alt={partner.title} />
              </Link>
            ) : (
              <img src={partner.src} alt={partner.title} />
            )}
          </li>
        ))}
    </Wrapper>
  );
};

export default FooterPartners;
