import React from 'react';
import styled from 'react-emotion';
import Link from '../../utility/Link.js';

const Wrapper = styled('nav')`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  margin-top: 20px;

  ${({ theme }) => theme.above.md} {
    justify-content: flex-start;
  }

  .link {
    margin: 10px 15px;

    ${({ theme }) => theme.above.md} {
      margin: 10px 30px;

      &:first-of-type {
        margin-left: 0px;
      }

      &:last-of-type {
        margin-right: 0px;
      }
    }
  }
`;

const FooterIntegrityLinks = ({ links }) => {
  return (
    <Wrapper>
      {links &&
        links.map((link, i) => (
          <Link key={i} to={link.to} target={link.targetBlank} className="link">
            {link.title}
          </Link>
        ))}
    </Wrapper>
  );
};

export default FooterIntegrityLinks;
