import { default as React, Component } from 'react';
import styled, { css } from 'react-emotion';

const UIMenuBtn = styled('div')`
  cursor: pointer;
  position: relative;
  display: block;
  height: 24px;
  width: 24px;
  transform: rotate(0deg) translate3d(0, 0, 0) scale(1, 1);
  transition: transform 250ms, opacity 250ms ease;
  z-index: 100;

  .menu-row {
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 100%;
    height: 2px;
    border-radius: 1px;
    background-color: ${({ theme }) => theme.colors.steelGray};
    opacity: 1;
    transform: translateY(-50%) translate3d(0, 0, 0) scale(1, 1);

    &.top {
      margin-top: -7px;
      transition: transform 250ms linear 0ms, margin 250ms linear 250ms;
    }

    &.mid {
      transition-duration: 0s;
      transition-delay: 250ms, 0s;
    }

    &.bot {
      margin-top: 7px;
      transition: transform 250ms linear 0ms, margin 250ms linear 250ms,
        max-width 250ms ease, left 250ms ease;
    }
  }

  ${({ open }) =>
    open &&
    css`
      transform: rotate(180deg) translate3d(0, 0, 0) scale(1, 1);

      .menu-row.top {
        margin-top: 0px !important;
        opacity: 1;
        transform: rotate(45deg);
        transition: margin 250ms linear 0ms, transform 250ms linear 250ms;
      }

      .menu-row.mid {
        opacity: 0;
        transition-delay: 250ms, 0s;
      }

      .menu-row.bot {
        margin-top: 0px !important;
        max-width: 100%;
        left: 0%;
        opacity: 1;
        transform: rotate(-45deg);
        transition: margin 250ms linear 0ms, transform 250ms linear 250ms,
          max-width 250ms ease, left 250ms ease;
      }
    `}
`;

class MenuBtn extends Component {
  toggleMenu = () => {
    const open = !this.props.open;

    if (this.props.onToggleMenu) {
      this.props.onToggleMenu(open);
    }
  };

  render() {
    return (
      <UIMenuBtn open={this.props.open} onClick={() => this.toggleMenu()}>
        <div className="menu-row top" />
        <div className="menu-row mid" />
        <div className="menu-row bot" />
      </UIMenuBtn>
    );
  }
}

//Export
export default MenuBtn;
