import React, { Component } from 'react';
import styled from 'react-emotion';
import { Quantity as JetshopQuantity } from '@jetshop/ui/Input/';

const Wrapper = styled('div')`
  display: flex;

  div {
    margin: 0px;
  }

  input {
    border-left: 0px;
    border-right: 0px;
  }

  label {
    display: none;
  }
`;

const Button = styled('button')`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 54px;
  height: 54px;
  color: ${({ theme }) => theme.colors.black};
  background-color: ${({ theme }) => theme.colors.white};
  border: 1px solid ${({ theme }) => theme.colors.black};
  outline: 0;
  box-shadow: none;
`;

class Quantity extends Component {
  componentDidMount() {
    let quantity = this.props.quantity !== undefined ? this.props.quantity : 1;

    this.setState({
      quantity: quantity
    });
  }

  state = {
    quantity: 1
  };

  prepareChange = e => {
    let value = /^\d+$/.test(e.target.value) ? parseInt(e.target.value) : 0;
    this.handleChange(value);
  };

  handleChange = value => {
    this.setState(
      {
        quantity: value
      },
      () => {
        if (this.props.handleChange) {
          this.props.handleChange(value);
        }
      }
    );
  };

  remove = () => {
    let { quantity } = this.state;
    if (quantity > 1) {
      this.handleChange(quantity - 1);
    }
  };

  add = () => {
    let { quantity } = this.state;
    this.handleChange(quantity + 1);
  };

  render() {
    const { quantity } = this.state;

    return (
      <Wrapper>
        <Button onClick={this.remove}>-</Button>

        <JetshopQuantity
          value={quantity}
          onChange={e => this.prepareChange(e)}
        />

        <Button onClick={this.add}>+</Button>
      </Wrapper>
    );
  }
}

export default Quantity;
