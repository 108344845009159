import React, { Component, Fragment } from 'react';
import styled, { css } from 'react-emotion';
import { Scrollbars } from 'react-custom-scrollbars';
import CategoryLink from '@jetshop/ui/CategoryLink';
import Link from '../../../utility/Link';
import { getServer } from '../../../utility/functions';
import { ReactComponent as UserIcon } from '../../../../svg/user-icon.svg';
import { theme } from '../../../Theme';
import { capitalize } from '../../../utility/functions';
import { ReactComponent as CloseIcon } from '../../../../svg/close.svg';
import { featuredCategoryIds } from '../../../utility/featuredCategoryIds';

const Level = styled('div')`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow-y: auto;
  ${({ menuOpening }) =>
    !menuOpening &&
    css`
      transition-property: all;
      transition-duration: 250ms;
      transition-delay: 0ms;
      transition-timing-function: linear;
    `}

  a::before,
  a::after {
    display: inline-block;
    height: 10px;
    width: 10px;
    border: 3px solid ${theme.colors.black};
    border-top: 0px;
    border-left: 0px;
    border-radius: 1px;
  }

  .item {
    border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  }

  .item a {
    letter-spacing: 0;
    padding: 15px 25px;
    display: inline-block;
    text-transform: ${({ topLevel }) => (topLevel ? 'uppercase' : 'none')};

    text-decoration: none;
    font-size: 16px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.black};

    &.has-sub-menu {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.has-sub-menu::after {
      content: '';
      margin-left: -10px;
      transform: translateY(-0.5px) rotate(-45deg);
    }
  }

  .title {
    text-transform: none;
    font-size: 16px;
    letter-spacing: 0;

    a {
      text-decoration: none;
      color: ${({ theme }) => theme.colors.black};
    }

    a::before {
      content: '';
      margin-right: 10px;
      transform: rotate(135deg);
    }
  }

  .login-link {
    a {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    svg {
      margin-right: -5px;
    }
  }
`;

const CloseButton = styled('button')`
  background-color: transparent;
  padding: 0;
  width: 24px;
  height: 24px;
  display: block;

  svg {
    margin: 0;
    width: 100%;
    height: 100%;
  }
`;

const Inner = styled('div')`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
`;

const NavList = styled('ul')`
  display: flex;
  min-height: 100%;
  flex-direction: column;
  justify-content: flex-start;
`;

const MenuHeader = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 25px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  background-color: ${({ theme, topLevel }) =>
    topLevel ? theme.colors.white : theme.colors.lightGrey};
`;

const MenuButtonContainer = styled('div')`
  width: 18px;
  & > * {
    width: 100% !important;
  }
`;

class MobileLevel extends Component {
  componentDidMount() {
    this.reveal = setTimeout(() => {
      this.setState({
        translateX: 0
      });
    }, 10);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    let { current, depth } = this.props;
    let translateX = 0;
    if (current !== depth) {
      translateX = current > depth ? 100 : -100;
    }

    if (this.state.translateX !== translateX) {
      this.setState({
        translateX: translateX
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.reveal);
  }

  state = {
    translateX: 100
  };

  render() {
    let {
      current,
      depth,
      currentCat,
      categories,
      animation,
      descendSubMenu,
      ascendSubMenu,
      closeMenu,
      ctaLink,
      loginLink,
      menuOpening
    } = this.props;

    //Animation classes, not needed for animation
    let levelClasses = [
      current === depth ? 'current' : current > depth ? 'ascend' : 'descend',
      current === depth && animation === 'descend' ? 'reveal' : null
    ];

    //Get translate value from state
    const { translateX } = this.state;

    //Variables
    let topLevel = current === 0;
    const _server = getServer();
    const inspoCatId = _server.inspoCatId;

    const standardCategories = categories.filter(
      s => !featuredCategoryIds.includes(s.id)
    );
    const featuredCategories = categories.filter(s =>
      featuredCategoryIds.includes(s.id)
    );

    const orderedCategories = [...featuredCategories, ...standardCategories];

    return (
      <Level
        className={levelClasses.join(' ')}
        style={{ transform: `translateX(${translateX}%)` }}
        topLevel={topLevel}
        menuOpening={menuOpening}
      >
        <Inner>
          <MenuHeader topLevel={topLevel}>
            <h3 className="title">
              {!topLevel ? (
                <a href="/" onClick={e => ascendSubMenu(e)}>
                  {capitalize(currentCat.name)}
                </a>
              ) : (
                'Meny'
              )}
            </h3>
            <MenuButtonContainer>
              <CloseButton onClick={this.props.closeMenu}>
                <CloseIcon />
              </CloseButton>
            </MenuButtonContainer>
          </MenuHeader>

          <Scrollbars>
            <NavList>
              {currentCat && (
                <li className="item sub-heading-size" key={currentCat.id}>
                  <CategoryLink onClick={closeMenu} category={currentCat}>
                    {capitalize(`Visa alla ${currentCat.name}`)}
                  </CategoryLink>
                </li>
              )}
              {orderedCategories &&
                orderedCategories.map((category, i) => {
                  let hasSubcategories =
                    category.hasSubcategories &&
                    category.id !== inspoCatId &&
                    !featuredCategoryIds.includes(category.id);

                  let categoryClasses = [
                    'sub-heading-size',
                    hasSubcategories ? 'has-sub-menu' : null
                  ];

                  return (
                    <li className="item" key={category.id}>
                      <CategoryLink
                        className={categoryClasses.join(' ')}
                        onClick={e =>
                          // If this category has child categories, we want to open them up with 'descendSubMenu'
                          // Otherwise, we want to follow the link and 'closeMenu'
                          hasSubcategories
                            ? descendSubMenu(e, category)
                            : closeMenu()
                        }
                        category={category}
                      >
                        {capitalize(category.name)}
                      </CategoryLink>
                    </li>
                  );
                })}

              {topLevel && (
                <Fragment>
                  {loginLink && (
                    <li className="item login-link">
                      <Link onClick={e => closeMenu()} to={loginLink}>
                        Mina sidor
                        <UserIcon />
                      </Link>
                    </li>
                  )}

                  {ctaLink && (
                    <li className="item">
                      <Link onClick={e => closeMenu()} to={ctaLink.to}>
                        {ctaLink.title}
                      </Link>
                    </li>
                  )}
                </Fragment>
              )}
            </NavList>
          </Scrollbars>
        </Inner>
      </Level>
    );
  }
}

export default MobileLevel;
