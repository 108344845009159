import React from 'react';
import styled from 'react-emotion';

import { SelectDropdown } from '@jetshop/ui/Select';

const StyledDropdown = styled(SelectDropdown)`
  display: block;
  height: auto;
  margin-bottom: 0px;
  border: none;
  background-color: transparent;

  button {
    display: block;
    background-color: transparent;
    border-color: ${({ theme }) => theme.colors.grey};
    border-left: none;
    border-right: none;
    text-align: center;

    ${({ theme }) => theme.above.md} {
      text-align: left;
      border: 0px;
    }
  }

  > button:focus {
    ${({ theme }) => theme.above.md} {
      border: 0px;
    }
  }

  div button {
    background-color: ${({ theme }) => theme.colors.lightGrey};

    ${({ theme }) => theme.above.md} {
      background-color: ${({ theme }) => theme.colors.white};
    }

    &:first-of-type {
      border-top: 1px solid ${({ theme }) => theme.colors.grey};
    }

    span {
      display: inline;
    }
  }

  &:last-of-type {
    button {
      border-bottom: none;
    }
  }

  h4 {
    padding: 15px;
    margin: 0px;
    ${({ theme }) => theme.fontSizes.subHeading}

    ${({ theme }) => theme.above.md} {
      padding: 10px 0px;
    }

    &::after {
      content: '';
      display: inline-block;
      width: 6px;
      height: 6px;
      border: 2px solid ${({ theme }) => theme.colors.black};
      border-left: 0px;
      border-top: 0px;
      margin: 0px 0px 4px 10px;
      transition: all 250ms ease;
      transform-origin: 75% 75%;
      transform: rotate(45deg);
    }
  }

  button[aria-expanded='false'] {
    h4::after {
      transform: rotate(-45deg);
    }
  }

  svg {
    display: none;
  }
`;

export const DefaultPicker = ({ option, ...props }) => {
  return (
    <StyledDropdown
      attribute={option.name}
      title={'Välj ' + option.name}
      {...props}
    />
  );
};
