import { fetchNewUserTrackingId } from './Api';

export const getHelloRetailTrackingId = async websiteUuid => {
  const cookieName = 'hello_retail_id';
  let cookieValue = getCookie(cookieName);

  if (!cookieValue) {
    const response = await fetchNewUserTrackingId(websiteUuid);
    cookieValue = response?.id;
    setCookie(cookieName, cookieValue);
  }

  return cookieValue;
};

export function splitSlug(slug) {
  const slugParts = slug.split('/');
  return slugParts.filter(part => part !== '');
}

function getCookie(cookieName) {
  const cookies = document.cookie.split(';');

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i].trim();
    if (cookie.startsWith(cookieName + '=')) {
      return cookie.substring(cookieName.length + 1);
    }
  }

  return null;
}

function setCookie(cookieName, cookieValue) {
  const expirationDate = new Date();
  expirationDate.setFullYear(expirationDate.getFullYear() + 1); // Set cookie expiration to 1 year from now

  const cookieString = `${cookieName}=${cookieValue};expires=${expirationDate.toUTCString()};path=/`;
  document.cookie = cookieString;
}
