import React, { Component } from 'react';
import styled from 'react-emotion';
import CategoryLink from '@jetshop/ui/CategoryLink';
import { capitalize } from '../../../utility/functions';
import { featuredCategoryIds } from '../../../utility/featuredCategoryIds';

const CategoryTitle = styled('span')`
  font-weight: bold;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`;

const Category = styled('li')``;

const SubcategoryTitle = styled('span')`
  font-size: 14px;
  color: ${({ theme }) => theme.colors.black};
`;

const Categories = styled('div')`
  display: flex;
  width: 100%;
  height: ${({ menuItemsHeight, cols }) => menuItemsHeight / cols}px;
  text-align: left;

  a {
    display: inline-block;
    width: 100%;
    padding: 5px 25px;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
    text-decoration-color: ${({ theme }) => theme.colors.black};
  }

  .category a {
    text-decoration-thickness: 2px;
  }

  .subcategory + .category,
  .category + .category {
    margin-top: 18px;
  }
`;

const StandardCategories = styled('ul')`
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 100%;
  flex: ${({ cols }) => cols};

  & > * {
    width: ${({ cols }) => 100 / cols}%;
  }
`;

const FeaturedCategories = styled('ul')`
  display: flex;
  flex-direction: column;
  flex: 1;

  a {
    font-weight: bold;
    text-decoration-thickness: 2px !important;
  }
`;

class SubMenu extends Component {
  render() {
    const viewportWidth = window.innerWidth;
    const { category, onSelect } = this.props;

    const standardCategories = category.subcategories.filter(
      s => !featuredCategoryIds.includes(s.id)
    );
    const featuredCategories = category.subcategories.filter(s =>
      featuredCategoryIds.includes(s.id)
    );

    const cols = viewportWidth > 1400 ? 4 : 3;
    // Total height of all items which must be rendered in the menu.
    const menuItemsHeight = standardCategories.reduce((acc, catLevel2) => {
      let total = 48;
      catLevel2.subcategories.forEach(() => {
        total += 28;
      });
      return total + acc;
    }, 140);

    return (
      <Categories menuItemsHeight={menuItemsHeight} cols={cols}>
        <FeaturedCategories>
          <Category>
            <CategoryLink onClick={onSelect} category={category}>
              <SubcategoryTitle>
                Visa alla {category.name.toLowerCase()}
              </SubcategoryTitle>
            </CategoryLink>
          </Category>
          {featuredCategories.map(cat => (
            <Category key={cat.id}>
              <CategoryLink onClick={onSelect} category={cat}>
                <SubcategoryTitle>{capitalize(cat.name)}</SubcategoryTitle>
              </CategoryLink>
            </Category>
          ))}
        </FeaturedCategories>
        <StandardCategories cols={cols}>
          {standardCategories.map(catLevel2 => [
            <Category className="category" key={catLevel2.id}>
              <CategoryLink onClick={onSelect} category={catLevel2}>
                <CategoryTitle>{capitalize(catLevel2.name)}</CategoryTitle>
              </CategoryLink>
            </Category>,
            ...catLevel2.subcategories.map(catLevel3 => (
              <Category className="subcategory" key={catLevel3.id}>
                <CategoryLink onClick={onSelect} category={catLevel3}>
                  <SubcategoryTitle>
                    {capitalize(catLevel3.name)}
                  </SubcategoryTitle>
                </CategoryLink>
              </Category>
            ))
          ])}
        </StandardCategories>
      </Categories>
    );
  }
}

export default SubMenu;
