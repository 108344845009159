import React, { Component } from 'react';
import styled from 'react-emotion';
import Measure from 'react-measure';
import CategoryLink from '@jetshop/ui/CategoryLink';
import MenuContainer from '@jetshop/ui/Menu/MenuContainer';
import SubMenuWrapper from './SubMenuWrapper';
import { getServer } from '../../../utility/functions';
import { capitalize } from '../../../utility/functions';

const Wrapper = styled('nav')`
  position: relative;
  text-align: center;
`;

const Menu = styled('ul')`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0 15px;
  list-style: none;
  text-align: left;
`;

const MainMenuItem = styled('li')`
  a,
  span {
    padding: 20px 10px;
    display: inline-block;
    color: ${({ theme }) => theme.colors.steelGray};
    text-decoration: none;
    cursor: pointer;
    ${({ theme }) => theme.fontSizes.description}

    ${({ theme }) => theme.above.xl} {
      ${({ theme }) => theme.fontSizes.regular}
    }

    &.active {
      color: ${({ theme }) => theme.colors.steelGray};
      font-weight: 700;
      text-decoration: underline;
    }

    &.selected {
      text-decoration: underline;
    }
  }
`;

class DesktopNav extends Component {
  componentDidMount() {
    window.addEventListener('click', this.handleClick);
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClick);
    window.removeEventListener('scroll', this.handleScroll);
  }

  state = {
    menuOpen: false,
    menuWidth: null
  };

  handleToggleMenu = menuOpen => {
    if (this.state.menuOpen !== menuOpen) {
      if (this.props.onToggleMenu) {
        this.setState({
          menuOpen: menuOpen
        });
        this.props.onToggleMenu(menuOpen);
      }
    }
  };

  closeMenu = () => {
    if (this.props.onToggleMenu) {
      this.setState({
        menuOpen: false
      });

      this.props.onToggleMenu(false);

      if (this.menuProps) {
        this.menuProps.clearActiveCategories();
      }
    }
  };

  handleScroll = () => {
    this.closeMenu();
  };

  handleClick = e => {
    if (this.ref) {
      if (!this.ref.contains(e.target)) {
        this.closeMenu();
      }
    }
  };

  render() {
    let { categories } = this.props;
    let that = this;

    const _server = getServer();
    const inspoCatId = _server.inspoCatId;

    if (categories.data && categories.data.categories) {
      categories = categories.data.categories;
    } else {
      categories = null;
    }

    return (
      <MenuContainer>
        {props => {
          let menuOpen =
            props.activeCategories.length > 0 &&
            props.activeCategories[0].subcategories.length > 0;

          that.menuProps = props;

          return (
            <Wrapper
              innerRef={ref => (this.ref = ref)}
              onMouseLeave={() => {
                /*that.menuProps.clearActiveCategories();
                that.handleToggleMenu(false);*/
              }}
            >
              <Measure
                bounds
                onResize={ref => {
                  this.setState({
                    menuWidth: ref.bounds.width
                  });
                }}
              >
                {({ measureRef }) => (
                  <Menu innerRef={measureRef}>
                    {categories
                      ? Object.values(categories).map(category => {
                          const hasSubcategories =
                            category.hasSubcategories &&
                            category.id !== inspoCatId;

                          const isActive =
                            typeof window !== 'undefined' &&
                            window.location.pathname.includes(
                              category.primaryRoute.path
                            );

                          let classes = [];
                          if (isActive) {
                            classes.push('active');
                          }
                          if (that.menuProps.isActiveCategory(category)) {
                            classes.push('selected');
                          }

                          return (
                            <MainMenuItem key={category.id}>
                              {!hasSubcategories ||
                              category.id === inspoCatId ? (
                                <CategoryLink
                                  onClick={() => {
                                    that.menuProps.clearActiveCategories();
                                    that.handleToggleMenu(false);
                                  }}
                                  category={category}
                                >
                                  {category.name}
                                </CategoryLink>
                              ) : (
                                <span
                                  className={classes.join(' ')}
                                  onClick={e => {
                                    if (
                                      that.menuProps.isActiveCategory(category)
                                    ) {
                                      that.menuProps.clearActiveCategories();
                                      that.handleToggleMenu(false);
                                    } else {
                                      that.menuProps.setActiveCategory(
                                        category
                                      );

                                      if (hasSubcategories) {
                                        that.handleToggleMenu(true);
                                      } else {
                                        that.handleToggleMenu(false);
                                      }
                                    }
                                  }}
                                >
                                  {capitalize(category.name)}
                                </span>
                              )}
                            </MainMenuItem>
                          );
                        })
                      : null}
                  </Menu>
                )}
              </Measure>

              <SubMenuWrapper
                pose={menuOpen ? 'open' : 'closed'}
                menuWidth={this.state.menuWidth}
                activeCategory={props.activeCategories[0]}
                onSelect={() => this.closeMenu()}
              />
            </Wrapper>
          );
        }}
      </MenuContainer>
    );
  }
}

export default DesktopNav;
