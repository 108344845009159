import React from 'react';
import styled from 'react-emotion';
import { Price as FlightPrice } from '@jetshop/ui/Price';
import ProductLink from '@jetshop/ui/ProductLink';
import { theme } from '../../Theme';

export const ProductPadding = styled('div')`
  padding: 15px;

  ${theme.below.lg} {
    padding: 10px;
  }

  ${theme.below.md} {
    padding: 10px;
  }

  ${theme.below.xs} {
    padding: 5px;
  }

  text-align: center;
  outline: 0;
  box-shadow: none;
`;

export const StyledProductLink = styled(ProductLink)`
  border: 0px;
  color: ${({ theme }) => theme.colors.black};
  text-decoration: none;
`;

export const Title = styled('h3')`
  font-weight: 700;

  ${({ theme }) => theme.below.xs} {
    ${({ theme }) => theme.fontSizes.description}
    line-height: 1.3;
    margin-top: 5px;
  }
`;

export const ArtNr = styled('span')`
  color: ${({ theme }) => theme.colors.grey};
  ${({ theme }) => theme.fontSizes.description}
  line-height: 1;

  ${({ theme }) => theme.below.xs} {
    font-size: 1rem;
  }
`;

const StyledPrice = styled(FlightPrice)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  font-weight: 800;
  font-size: 20px;

  ${({ theme }) => theme.below.xs} {
    ${({ theme }) => theme.fontSizes.description}
    line-height: 1.3;
  }

  ${FlightPrice.New} {
    color: red;
    margin-bottom: 2px;
  }
`;

export const Price = ({ price, previousPrice }) => (
  <StyledPrice price={price} previousPrice={previousPrice} />
);
