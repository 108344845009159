import { getHelloRetailTrackingId, splitSlug } from './Helper';

export const fetchHelloRetailRecoms = async (websiteUuid, recomInputs) => {
  let result;

  const currentUrl = window.location.href;
  const url = new URL(currentUrl);
  const currentSlug = url.pathname;

  const trackingId = await getHelloRetailTrackingId(websiteUuid);

  let data = {
    websiteUuid: websiteUuid,
    trackingUserId: trackingId,
    requests: []
  };

  for (let recom of recomInputs) {
    let requestObj = {
      key: recom?.recommendationId,
      format: 'json',
      context: {
        urls: ['https://nyamusik.se' + currentSlug]
      }
    };

    if (recom?.parents?.length > 0) {
      const hierarchies = recom?.parents
        ?.map(parent => parent.object?.breadcrumbText)
        .reverse();
      requestObj.context.hierarchies = [hierarchies];
    }
    data.requests.push(requestObj);
  }

  try {
    const response = await fetch('https://core.helloretail.com/serve/recoms', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(data)
    });

    if (response.ok) {
      result = await response.json();
    } else {
      console.error('Error fetching data for Hello Retail:', response.status);
    }
  } catch (error) {
    console.error(
      'An error occurred while fetching data for Hello Retail:',
      error
    );
  }

  return result;
};

export const fetchNewUserTrackingId = async websiteId => {
  let result;

  let data = {
    websiteUuid: websiteId
  };

  try {
    const response = await fetch(
      'https://core.helloretail.com/serve/trackingUser',
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
      }
    );

    if (response.ok) {
      result = await response.json();
    } else {
      console.error('Error fetching data for Hello Retail:', response.status);
    }
  } catch (error) {
    console.error(
      'An error occurred while fetching data for Hello Retail:',
      error
    );
  }

  return result;
};
