import * as translations from '../translations';
import channelsQuery from './ChannelsQuery.gql';
import { addwishTracker } from '@jetshop/flight-addwish';

let shopid = 'nyamusik';

export default {
  apolloConfig: {
    shopid: shopid,
    graphQLURI:
      process.env.REACT_APP_GRAPHQL_URI || 'https://storeapi.jetshop.io',
    token: '359fd7c1-8e72-4270-b899-2bda9ae6ef57',
    engineApiKey: '',
    enableGateway: false,
    channelsQuery
  },
  nostoAccountID: process.env.REACT_APP_NOSTO_ACCOUNT_ID,
  addwishAccountID: '8B8147C2903BC7B01D250A1A124D2AA8',
  trackers: [addwishTracker('8B8147C2903BC7B01D250A1A124D2AA8')],
  trackingID: process.env.REACT_APP_GA_TRACKING_ID || 'UA-123334166-1',
  tagManagerID: process.env.REACT_APP_GTM_CONTAINER_ID || 'GTM-MJSQFD',
  relewareEnabled: true,
  intl: {
    translations,
    defaultLocale: 'en',
    options: {
      formats: {
        number: {
          EUR: { style: 'currency', currency: 'EUR' },
          SEK: { style: 'currency', currency: 'SEK' },
          USD: { style: 'currency', currency: 'USD' },
          GBP: { style: 'currency', currency: 'GBP' },
          NKR: { style: 'currency', currency: 'NKR' },
          AUD: { style: 'currency', currency: 'USD' }
        }
      }
    }
  },
  channelOverrides: {},
  disableGeoRedirect: false,
  singleDomainMode: false,
  schemaExtensions: [],
  routes: {
    church: {
      id: 62,
      path: '/kyrka'
    },
    school: {
      id: 63,
      path: '/musikaffar'
    },
    brandsCategory: {
      id: 913
    },
    authorsCategory: {
      id: 873
    }
  },
  useArticleNumberAsId: true
};
