import React, { Component } from 'react';
import styled from 'react-emotion';
import posed from 'react-pose';
import Container from '../../Container';
import SubMenu from './SubMenu';

const PosedWrapper = styled(
  posed('section')({
    open: {
      height: 'auto'
    },
    closed: {
      height: 0
    }
  })
)`
  position: absolute;
  right: 0;
  left: 50%;
  width: 100vw;
  overflow: hidden;
  transform: translateX(-50%);
  z-index: 10;
  box-shadow: 0px 33px 33px -20px rgb(0 0 0 / 9%);
`;

const StyledContainer = styled(Container)`
  flex-direction: row;
  padding: 20px 5px 20px 5px !important;
  background-color: ${({ theme }) => theme.colors.standardBackground};
  max-height: calc(85vh - 212px);
  overflow: auto;
`;

const MenuContent = styled('div')`
  height: 100%;
  width: ${({ width }) => (width ? width + 'px' : '100vw')};
  margin: auto;
`;

class SubMenuWrapper extends Component {
  render() {
    let { pose, menuWidth, onSelect, activeCategory } = this.props;

    return (
      <PosedWrapper pose={pose}>
        <StyledContainer fullWidth>
          <MenuContent width={menuWidth}>
            {activeCategory && (
              <SubMenu
                category={activeCategory}
                onSelect={onSelect}
                menuWidth={menuWidth}
              />
            )}
          </MenuContent>
        </StyledContainer>
      </PosedWrapper>
    );
  }
}

export default SubMenuWrapper;
