import React from 'react';
import styled from 'react-emotion';
import { TrendLink } from '../../ui/Button';
import { ReactComponent as CampaginIcon } from '../../../svg/shopping-bag.svg';

const Wrapper = styled('div')`
  ${({ theme }) => theme.below.md} {
    display: none;

    &.menuOpen {
      display: block;
    }
  }
`;

const IconWrapper = styled('span')`
  width: 20px;
  height: 20px;
  margin-left: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg {
    height: 20px;

    path {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
`;

const Button = styled(TrendLink)`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none;
`;

const CampaignRow = ({ menuOpen, link, className, ...rest }) => {
  if (link) {
    let classes = 'CampaignRow ' + className;
    if (menuOpen) classes += ' menuOpen';

    return (
      <Wrapper className={classes} {...rest}>
        <Button to={link.to} target={link.targetBlank}>
          {link.title}
          <IconWrapper>
            <CampaginIcon />
          </IconWrapper>
        </Button>
      </Wrapper>
    );
  } else {
    return null;
  }
};

export default CampaignRow;
