import PropTypes from 'prop-types';
import React from 'react';
import StockOrb from './StockOrb';

const StockStatusIndicator = ({ status, text }) => {
  return (
    <>
      <p css={{ marginBottom: '10px', fontSize: '1.4rem' }}>
        <StockOrb status={status} />
        {text}
      </p>
    </>
  );
};

StockStatusIndicator.propTypes = {
  status: PropTypes.oneOf(['inStock', 'outOfStock', 'notifyWhenBack']),
  text: PropTypes.string
};

export default StockStatusIndicator;
